



import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default  function AverageOptIcon(props:any) {
   
    return (

       <SvgIcon fontSize="small"  viewBox="0 0 16 16">

<path d="M12.995 0.00011417H8.66336V1.32677H12.995C13.986 1.32677 14.6573 1.99809 14.6573 2.98904V12.9789C14.6573 13.9699 13.986 14.6412 12.995 14.6412H8.66336V15.9679H12.995C14.993 15.9679 16 14.9769 16 12.9629V2.97299C16 0.991055 15.009 0 12.995 0V0.00011417ZM1.34271 12.979V2.98909C1.34271 1.99812 2.01404 1.32683 3.00498 1.32683H7.33664V0.000171255H3.00498C1.00699 0.000171255 0 0.991141 0 3.00513V12.995C0 14.993 0.990977 16 3.00498 16H7.33664V14.6733H3.00498C2.01401 14.6573 1.34271 13.986 1.34271 12.979V12.979Z" fill="currentColor"/>

       </SvgIcon>
    )


}

