
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
export default function boundingBox(props:any) {
    return (
        <>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 8L12 13.7502V25.2503L20 31L28 25.2503V13.7502L20 8ZM24.2795 16.1469L20.1928 13.2094V8.69291L27.4216 13.8887L24.2795 16.1469ZM15.9131 16.8398L19.8074 19.6386V25.2369L15.9131 22.4376V16.8398ZM20.193 19.6384L24.0869 16.8396V22.4376L20.1928 25.2364L20.193 19.6384ZM20.0002 19.2229L16.106 16.4241L20 13.6251L23.8942 16.4241L20.0002 19.2229ZM19.8072 13.2094L15.7205 16.1469L12.5784 13.8887L19.8072 8.69291V13.2094ZM15.5275 16.5626V22.4379L12.3856 24.6961V14.3046L15.5275 16.5626ZM15.7205 22.8533L19.8072 25.7908V30.3073L12.5784 25.1118L15.7205 22.8533ZM20.1928 25.7908L24.2797 22.8533L27.4216 25.1118L20.1928 30.3073V25.7908ZM24.4725 22.4376V16.5626L27.6144 14.3046V24.6961L24.4725 22.4376Z" fill="#888888"/>
            </svg>

        </>
    )
}


