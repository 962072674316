
export const defaultData = {

    labels:{

        label2d:'2D Note',
        label3d:'3D Note',
        probeLabel:'{{ defaultProbe }}',
    },
    animations:{
        linear:'Linear',
    }
}