import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default  function PopOut(props:any) {
   
    return (

       <SvgIcon {...props}  viewBox="0 0 16 16">

         <path d="M9.77778 0V1.77778H12.9689L4.23111 10.5156L5.48444 11.7689L14.2222 3.03111V6.22222H16V0H9.77778ZM14.2222 14.2222H1.77778V1.77778H8V0H1.77778C0.791111 0 0 0.8 0 1.77778V14.2222C0 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V8H14.2222V14.2222Z" fill="currentColor"/>

       </SvgIcon>
    )
}