import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LabelArcPoint(props:any) {
	
	return (
	
		<SvgIcon {...props} viewBox="0 0 18 18">
		 
			<path d="M1.5 11C1.10218 11 0.720645 11.158 0.43934 11.4393C0.158036 11.7206 0 12.1022 0 12.5C0 13.3325 0.675 14 1.5 14C2.3325 14 3 13.3325 3 12.5C3 12.1022 2.84196 11.7206 2.56066 11.4393C2.27936 11.158 1.89782 11 1.5 11Z" fill="currentColor"/>
			<path d="M9 11C8.60218 11 8.22064 11.158 7.93934 11.4393C7.65804 11.7206 7.5 12.1022 7.5 12.5C7.5 13.3325 8.175 14 9 14C9.8325 14 10.5 13.3325 10.5 12.5C10.5 12.1022 10.342 11.7206 10.0607 11.4393C9.77936 11.158 9.39782 11 9 11Z" fill="currentColor"/>
			<path d="M16.5 11C16.1022 11 15.7206 11.158 15.4393 11.4393C15.158 11.7206 15 12.1022 15 12.5C15 13.3325 15.675 14 16.5 14C17.3325 14 18 13.3325 18 12.5C18 12.1022 17.842 11.7206 17.5607 11.4393C17.2794 11.158 16.8978 11 16.5 11Z" fill="currentColor"/>
			<path d="M18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 1.8021e-07 9 0C6.61305 -1.8021e-07 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 3.60419e-07 6.61305 0 9H3.27192C3.27192 7.48082 3.87542 6.02386 4.94964 4.94964C6.02386 3.87542 7.48082 3.27192 9 3.27192C10.5192 3.27192 11.9761 3.87542 13.0504 4.94964C14.1246 6.02386 14.7281 7.48082 14.7281 9H18Z" fill="currentColor"/>

		</SvgIcon>
	
	)
}






















