import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FaceLabel(props:any) {
	
	return (
	
		<SvgIcon {...props} viewBox="0 0 18 14">
		 
		 <path d="M12.3158 12H1.89474V2H12.3158L15.6789 7L12.3158 12ZM13.86 0.84C13.5189 0.33 12.9505 0 12.3158 0H1.89474C1.39222 0 0.910288 0.210714 0.554955 0.585786C0.199623 0.960859 0 1.46957 0 2V12C0 12.5304 0.199623 13.0391 0.554955 13.4142C0.910288 13.7893 1.39222 14 1.89474 14H12.3158C12.9505 14 13.5189 13.66 13.86 13.15L18 7L13.86 0.84Z" fill="currentColor"/>
         <path d="M8 7C7.73478 7 7.48043 7.10536 7.29289 7.29289C7.10536 7.48043 7 7.73478 7 8C7 8.555 7.45 9 8 9C8.555 9 9 8.555 9 8C9 7.73478 8.89464 7.48043 8.70711 7.29289C8.51957 7.10536 8.26522 7 8 7Z" fill="currentColor"/>
         <path d="M4.5359 10L8 4L11.4641 10H4.5359Z" stroke="currentColor"/>
		 
		</SvgIcon>
	
	)
}