import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LabelNotes(props:any) {
	
	return (
	
		<SvgIcon {...props} viewBox="0 0 14 18">
		 
		  <path d="M10.6667 0H1.77778C1.30628 0 0.854097 0.210714 0.520699 0.585786C0.187301 0.960859 0 1.46957 0 2V16C0 16.5304 0.187301 17.0391 0.520699 17.4142C0.854097 17.7893 1.30628 18 1.77778 18H14.2222C14.6937 18 15.1459 17.7893 15.4793 17.4142C15.8127 17.0391 16 16.5304 16 16V6L10.6667 0ZM14.2222 16H1.77778V2H9.77778V7H14.2222V16ZM12.4444 11H3.55556V9H12.4444V11ZM9.77778 14H3.55556V12H9.77778" fill="currentColor"/>
		
		</SvgIcon>
	
	)
}