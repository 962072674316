import MuiIconButton from "@material-ui/core/IconButton";
import Title from "../../layout/sideBar/sideBarContainer/sideBarHeader/utilComponents/Title";

import SideBarContainer from "../../layout/sideBar/sideBarContainer";
import BackButton from "../../icons/back";

import { useAppDispatch, useAppSelector } from "../../../store/storeHooks";

import { goBack } from "connected-react-router/immutable";
import { FileImport } from "tabler-icons-react";
import { FileExport } from "tabler-icons-react";

import React, { useEffect } from "react";
import {
selectSlideData,
  selectRootIds,
  expandNode,
  setSlideSelection,
  setSelectedSlideId,
  createNode,
  applyView,
  replaceViewData,
  deleteNode,
  SlideType,
  pasteSlide,
  downloadFile,
  downloadParentFolder,
  updateSlideData,
  selectAppliedSlide,
  selectSelectedSlide,
  selectViewpathCounter,
  selectViewpointCounter,
  pasteGroup,
  setNewTitle,
  setIsTitleEditable
} from "../../../store/sideBar/slideSlice";

import MuiGrid from "@material-ui/core/Grid";

// import GridViewIcon from "../../icons/gridView";
// import MuiFormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";

import ReplaceIcon from "../../icons/replace";
import MuiFileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import MuiPaste from "@material-ui/icons/AssignmentOutlined";
import MuiDeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

import OptionContainer from "../../layout/sideBar/sideBarContainer/sideBarFooter/utilComponents/OptionContainer";
import Option from "../../layout/sideBar/sideBarContainer/sideBarFooter/utilComponents/Option";
import MuiButton from "@material-ui/core/Button";
import MuiTypography from "@material-ui/core/Typography";

import CreateNewFolderIcon from "../../icons/createNewFolder";

// import MuiSlideshowIcon from "@material-ui/icons/Slideshow";

import { useState,useRef } from "react";

// import { useReducer, useEffect, Children } from "react";
import useContainer from "../../../customHooks/useContainer";


import TreeView from "components/shared/RcTree/Slides/SlidesTreeView";
import { convertListToTree } from "../../utils/tree";

import sizeCalculator from "../../../customHooks/useSizeCalculator";
import useLocalStorage from "customHooks/useLocalStorage";

//HeaderIconButton

import HeaderIconButton from "../../shared/headerIconButton/IconButton";
import nextId from "react-id-generator";
import viewpointSlice, {
  applyViewpointAsync,
  captureViewpointAsync,
  deleteViewpointAsync,
  selectViewPoint,
  updateViewpoint,
  saveViewpoint,
} from "store/viewpointSlice";
import { selectModelName } from "../../../store/appSlice";
import { TOUR_MENU_NAMES } from 'components/utils/tourMenus';
import HelpIcon from '@material-ui/icons/HelpOutline';

import { DialogueProps, dialogueState } from 'store/tutorialSlice';
import globalThemes from "theme/globalThemes";
import { nanoid } from '@reduxjs/toolkit';


export default function Slides() {
  const treeDataRedux = useAppSelector(selectSlideData);
  const treeRootIds = useAppSelector(selectRootIds);
  const { roots, expanded } = convertListToTree(treeDataRedux, treeRootIds);
  const viewPointCounter = useAppSelector(selectViewpointCounter);
  const viewPathCounter = useAppSelector(selectViewpathCounter);
  const selectedSlideId = useAppSelector((state) => state.slide.selectedSlide);
  const appliedSlideId = useAppSelector((state) => state.slide.appliedSlide);

  const classes = globalThemes();



  const viewPoint = useAppSelector(selectViewPoint);
  const modelName = useAppSelector(selectModelName);

  const [openDelete, setOpenDelete] = useState(false);
  const [copied, setCopied] = useState<any>();

  const [listView, setListView] = useState<boolean>(true);
  const [viewPointData, setViewPointData] = useLocalStorage(
    "viewPoint-" + modelName
  );
  const containerRef = useRef(null);
  const [containerWidth, containerHeight] = useContainer(containerRef, [
    treeDataRedux,
  ]);


  const dispatch = useAppDispatch();

  const onClickBackIcon = () => {
    dispatch(goBack());
  };

  let dialogProps:DialogueProps={
    dialogueRun: true,
    tourName: TOUR_MENU_NAMES.SLIDES
  }

  const getHeaderRightIcon=()=>{
    const onHelpClick = (e:any) => {
      dispatch(dialogueState(dialogProps));
      }
  
    return(
      <>
    <HeaderIconButton icon={<HelpIcon />} label={"helpIcon"} disabled={false} onClick={onHelpClick}></HeaderIconButton>
    </>
    )
  }
  const handleSwitchView = () => {
    setListView(!listView);
  };

  const handleSwitchFromView = (nodeId: string) => {
    setOpenDelete(false);
    dispatch(setSlideSelection(nodeId));
    dispatch(expandNode({ toOpen: true, nodeId }));
    setListView(false);
  };

  const handleExpand = (toOpen: boolean, nodeId: any) => {
    if (treeDataRedux[nodeId.node.id].slideType === SlideType.GROUP) {
      let toOpenval = treeDataRedux[nodeId.node.id].state.expanded
      toOpenval=!toOpenval
      dispatch(expandNode({ toOpen: toOpenval, nodeId: nodeId.node.id }));
    }
  };

  const handleRowClick = (node: any) => {
    setOpenDelete(false);
    dispatch(setSlideSelection(node.id));
  };

  const onHandleCreateGroup = () => {
    const slideId = "slide-group-" + nanoid(); //nextId("slide-group-");
    dispatch(createNode({ pid: "-1", id: slideId, options: {} }));
  };

  const handleCreateNode = (nodeId: string) => {
    let slideId = "slide-leaf-" + nanoid(); //nextId("slide-leaf-");
    while(viewPointData?.slides?.data[slideId]){
      slideId = "slide-leaf-" + nanoid(); //nextId("slide-leaf-")
    }
    dispatch(captureViewpointAsync({ id: slideId }));
    dispatch(createNode({ pid: nodeId, id: slideId, options: {} }));
  };

  const onHandleApply = () => {
    dispatch(downloadFile(selectedSlideId));
    dispatch(applyView(selectedSlideId));
    dispatch(applyViewpointAsync({ id: selectedSlideId }));
  };


useEffect(()=>{
  if(viewPointCounter>0)
  {
  let parentCounter=0;
  Object.values(treeDataRedux).forEach((key)=>{
    parentCounter++;
    if(parentCounter==1) {

    if(key.children.length >0) {
      let counter=0;
      Object.values(key.children).forEach((item:any)=>{
counter++;
if(counter===1){
  dispatch(applyView(treeDataRedux[item].id))
  dispatch(setSelectedSlideId(treeDataRedux[item].id))
}
      })
    
    }
  }
  })
  }
},[treeDataRedux])


  const onHandleDownload = () => {
    const toDownload = (id: string) => {
      treeDataRedux[id].children.map((item: string) => {
        if (treeDataRedux[id].slideType === SlideType.VIEW)
          dispatch(downloadFile(item));
        else toDownload(item);
      });
      dispatch(downloadFile(id));
    };
    toDownload(selectedSlideId);
  };

  const onHandleReplace = () => {

    dispatch(captureViewpointAsync({ id: selectedSlideId}));
    
    
  };

  const onHandleDeleteButton = () => {
    setOpenDelete(true);
  };

  const onHandleImport = () => {
  
    if (viewPointData && viewPointData.viewpoints)
      dispatch(updateViewpoint(viewPointData.viewpoints));
    if (viewPointData && viewPointData.slides)
      dispatch(updateSlideData(viewPointData.slides));
     

  }; 


  const onHandleExport = () => {
    let slides = {
      data: treeDataRedux,
      rootIds: treeRootIds,
      viewpathCounter: viewPathCounter,
      viewpointCounter: viewPointCounter,
      appliedSlide: appliedSlideId,
      selectedSlide: selectedSlideId,
    };
    let vpData = {
      slides: slides,
      viewpoints: viewPoint.viewpoints,
    };
    setViewPointData(vpData);
  };

  /*
  useEffect(() => onHandleExport(),[viewPoint,selectedSlideId,appliedSlideId])
  useEffect(() => {
    if(viewPoint?.viewpoints?.length<=0){
      onHandleImport();
      dispatch(setSlideSelection("-1"));
      if(viewPointData && viewPointData.slides && (viewPointData?.slides?.appliedSlide !== "8")){
        dispatch(downloadFile(viewPointData.slides.appliedSlide));
        dispatch(applyView(viewPointData.slides.appliedSlide));
        dispatch(applyViewpointAsync({ id: viewPointData.slides.appliedSlide }));
        dispatch(setSlideSelection(viewPointData.slides.selectedSlide))
       
      }
    }
  },[])
  */

  const onHandleDelete = () => {
    dispatch(deleteNode(selectedSlideId));
    dispatch(deleteViewpointAsync({ id: selectedSlideId }));
    setOpenDelete(false);
  };

  const onHandleCopy = () => {
    const newCopy = JSON.parse(JSON.stringify(treeDataRedux[selectedSlideId]));
    setCopied(newCopy);
  };

  const onHandleIsTitleEditable = (nodeId:string,editable:boolean) =>{
    dispatch(setIsTitleEditable({nodeId:nodeId,isEditable:editable}));
  }
  const setNewTitleTitle = (nodeId:string,newTitle:string)=> {

    dispatch(setNewTitle({nodeId:nodeId,newTitle:newTitle}));
  }

  // const onHandlePaste = () => {
  //   if (selectedSlideId !== "-1") {
  //     if (treeDataRedux[selectedSlideId].slideType === SlideType.GROUP) {
  //       dispatch(
  //         pasteSlide({ copied: copied, pid: "-1", newId: selectedSlideId })
  //       );
  //       console.log("9ff9f9");
  //     }
  //     if (treeDataRedux[selectedSlideId].slideType === SlideType.VIEW) {
  //       dispatch(
  //         pasteSlide({
  //           copied: copied,
  //           pid: treeDataRedux[selectedSlideId].pid,
  //           newId: selectedSlideId,
  //         })
  //       );
  //       console.log("9ff9f9");
  //     }
  //   } else {
  //     dispatch(
  //       pasteSlide({ copied: copied, pid: "-1", newId: selectedSlideId })
  //     );
  //     console.log("9ff9f9");
  //   }
  // };
  const handleRowUnselect = () =>{

    dispatch(setSlideSelection("-1"));

  }

  const onHandlePaste = () => {
    if (copied.pid === "-1") {
      const slideId = "slide-group-" + nanoid(); //nextId("slide-group-");
      dispatch(
        pasteGroup({
          copied: copied,
          pid: "-1",
          newId: slideId,
          viewPointsData: viewPoint.viewpoints,
        })
      );
    } else {
      let slideId = "slide-leaf-" + nanoid(); //nextId("slide-leaf-");
      while(viewPointData.slides.data[slideId]){
        slideId = "slide-leaf-" + nanoid(); //nextId("slide-leaf-")
      }
      let selectedViewPoint = viewPoint.viewpoints.find(
        (e) => e.uid === copied.id
      );
      let objCopy = { ...selectedViewPoint };
      objCopy.uid = slideId;

      if (selectedSlideId !== "-1") {
        dispatch(saveViewpoint(objCopy));
        if (treeDataRedux[selectedSlideId].pid !== "-1") {
          dispatch(
            pasteSlide({
              copied: copied,
              pid: treeDataRedux[selectedSlideId].pid,
              newId: slideId,
            })
          );
        } else
          dispatch(
            pasteSlide({
              copied: copied,
              pid: treeDataRedux[selectedSlideId].id,
              newId: slideId,
            })
          );
      } else {
        dispatch(
          pasteSlide({ copied: copied, pid: copied.pid, newId: slideId })
        );
      }
    }
  };

  const getHeaderLeftIcon = () => {
    return (
      <MuiIconButton onClick={onClickBackIcon}>
        <BackButton />
      </MuiIconButton>
    );
  };

  // const getHeaderRightIcon = () => {
  //   return (
  //     <div>
  //       {/* {listView ? (
  //         // <GridViewIcon />
  //         <HeaderIconButton
  //           icon={<GridViewIcon />}
  //           label={"Grid View"}
  //           disabled={false}
  //           onClick={handleSwitchView}
  //         ></HeaderIconButton>
  //       ) : (
  //         // <MuiFormatListBulletedIcon/>
  //         <HeaderIconButton
  //           icon={<MuiFormatListBulletedIcon />}
  //           label={"Bullet View"}
  //           disabled={false}
  //           onClick={handleSwitchView}
  //         ></HeaderIconButton>
  //       )} */}
  //     </div>
  //   );
  // };

  const getAction = () => {
    return null;
  };

  const getBody = () => {
    return (
      <div
        ref={containerRef}
        // style={{ height: "100%", background: "transparent" }}
      >
        <TreeView
          treeData={roots}
          defaultExpandAll
          expanded={expanded}
          nodes={treeDataRedux}
          containerwidth={containerWidth}
          onExpand={handleExpand}
          isTitleEditable = {true}
          onHandleIsTitleEditable = {onHandleIsTitleEditable}
          setNewTitleTitle = {setNewTitleTitle}
           selectedSlideId={selectedSlideId}
          // selected={[selectedSlideId]}
           selectedKey={[selectedSlideId]}
          appliedSlideId={appliedSlideId}
          handleExpand={handleExpand}
          handleRowClick={handleRowClick}
          handleCreateNode={handleCreateNode}
          handleRowUnselect={handleRowUnselect}
          unselect = {true}
        />
      </div>
      // <span>
      //   {listView ? (
      //     <ListView
      //       treeData={treeDataRedux}
      //       rootIds={treeRootIds}
      //       selectedSlideId={selectedSlideId}
      //       appliedSlideId={appliedSlideId}
      //       handleExpand={handleExpand}
      //       handleRowClick={handleRowClick}
      //       handleCreateNode={handleCreateNode}
      //       slideType={SlideType}
      //       handleSwitchView={handleSwitchFromView}
      //     />
      //   ) : (
      //     <GridMode
      //       treeData={treeDataRedux}
      //       rootIds={treeRootIds}
      //       selectedSlideId={selectedSlideId}
      //       appliedSlideId={appliedSlideId}
      //       handleExpand={handleExpand}
      //       handleRowClick={handleRowClick}
      //       handleCreateNode={handleCreateNode}
      //       slideType={SlideType}
      //     />
      //   )}
      // </span>
    );
  };

  const getFooter = () => {
    return (
      <div>
        {selectedSlideId === "-1" ? (
          <OptionContainer justify='space-evenly' style={{display:'flex',justifyContent:"space-evenly !important"}} >
            <Option
              label="Create"
              icon={CreateNewFolderIcon}
              onClickUpdate={onHandleCreateGroup}
            />
            <Option
              label="Paste"
              active={
                !copied ||
                copied.slideType === SlideType.VIEW ||
                !Object.keys(treeDataRedux).includes(copied.id)
              }
              icon={MuiPaste}
              onClickUpdate={onHandlePaste}
            />

            {/* <Option
              label="Import"
              icon={FileImport}
              onClickUpdate={onHandleImport}
              //  active={slideStore ? }
            />
            <Option
              label="Export"
              // active={}
              icon={FileExport}
              onClickUpdate={onHandleExport}
            /> */}
          </OptionContainer>
        ) : !openDelete ? (
          <div style={{ marginTop: "20px" }}>
            <MuiGrid container>
              {treeDataRedux[selectedSlideId] &&
              selectedSlideId !== appliedSlideId ? (
                <MuiGrid item xs={10}>
                  {treeDataRedux[selectedSlideId].slideType ===
                  SlideType.GROUP ? (
                    <MuiGrid container>
                      <MuiGrid item xs={4}>
                        {treeDataRedux[selectedSlideId].downloaded ||
                        treeDataRedux[selectedSlideId].children.length === 0
                          ? null
                          : `${sizeCalculator(
                              treeDataRedux[selectedSlideId].size
                            )}`}
                      </MuiGrid>
                      {/* {treeDataRedux[selectedSlideId].downloaded === false ? (
                        <MuiGrid item xs={6}>
                          <MuiButton
                            style={{
                              backgroundColor: "#5958FF",
                              width: "100%",
                              fontSize: "9px",
                            }}
                            autoFocus
                            disabled={
                              treeDataRedux[selectedSlideId].children.length ===
                              0
                            }
                            onClick={onHandleDownload}
                          >
                            Download
                          </MuiButton>
                        </MuiGrid>
                      ) : (
                        <MuiGrid item xs={2}></MuiGrid>
                      )} */}
                      {/* <MuiGrid item xs={2}>
                        <MuiIconButton
                          style={{ marginTop: "-10px" }}
                          disabled={
                            treeDataRedux[selectedSlideId].downloaded === false
                          }
                          // onClick={onHandleReplace}
                        >
                          <MuiSlideshowIcon />
                        </MuiIconButton>
                      </MuiGrid> */}
                    </MuiGrid>
                  ) : (
                    <MuiGrid container style={{display:"flex",justifyContent:"center",alignItems:"center", width:"85%"}}>
                      <MuiGrid item xs={4}>
                        {treeDataRedux[selectedSlideId].downloaded
                          ? null
                          : `${sizeCalculator(
                              treeDataRedux[selectedSlideId].size
                            )}`}
                      </MuiGrid>
                      <MuiGrid item xs={6}>
                        <MuiButton
                          // style={{
                          //   backgroundColor: "#5958FF",
                          //   width: "100%",
                          //   fontSize: "9px",
                          // }}
                          style={{width:'max-content'}}
                          className = {classes.Muibtn}
                          autoFocus
                          onClick={onHandleApply}
                        >
                          {treeDataRedux[selectedSlideId].downloaded === true
                            ? "Apply"
                            :
                              "Apply"}
                        </MuiButton>
                      </MuiGrid>
                    </MuiGrid>
                  )}
                </MuiGrid>
              ) : null}
            </MuiGrid>

            <OptionContainer>
              {treeDataRedux[selectedSlideId] ? (
                <>
                  <Option
                    label="Replace"
                    active={
                      selectedSlideId === "-1" ||
                      treeDataRedux[selectedSlideId].slideType ===
                        SlideType.GROUP
                    }
                    icon={ReplaceIcon}
                    onClickUpdate={onHandleReplace}
                  />

                  <Option
                    label="Copy"
                    active={selectedSlideId === "-1"}
                    icon={MuiFileCopyOutlinedIcon}
                    onClickUpdate={onHandleCopy}
                  />
                  <Option
                    label="Paste"
                    active={
                      !copied || !Object.keys(treeDataRedux).includes(copied.id)
                    }
                    icon={MuiPaste}
                    onClickUpdate={onHandlePaste}
                  />
                  <Option
                    label="Delete"
                    active={
                      selectedSlideId === appliedSlideId ||
                      selectedSlideId === "-1"
                    }
                    icon={MuiDeleteForeverOutlinedIcon}
                    onClickUpdate={onHandleDeleteButton}
                  />
                </>
              ) : null}
            </OptionContainer>
          </div>
        ) : (
          <div style={{margin:"20px 10px"}}>
            <div style={{ marginBottom: "5px", marginTop: "5px" }}>
              <MuiTypography style={{ marginBottom: "5px", fontSize: "14px" }}>
                Are you sure want to delete ?
              </MuiTypography>
              <div style={{display:'flex', gap:'10px', alignContent: "center",justifyContent:"center"}}>
                <MuiButton
                  // 
                  className={classes.Muibtn}
                  autoFocus
                  onClick={onHandleDelete}
                  // color="primary"
                >
                  Confirm
                </MuiButton>
                <MuiButton
                  // style={{ width: "20%", fontSize: "9px" }}
                  className = {classes.BtnOutlined}
                  onClick={() => setOpenDelete(false)}
                  // color="primary"
                >
                  Cancel
                </MuiButton>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <SideBarContainer
  
    headerContent={ <Title text={"3D Slides"} /> }
      headerAction={getAction()}
      headerRightIcon={getHeaderRightIcon()}
      body={getBody()}
      footer={getFooter()}
    />
  );
}
