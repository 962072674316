import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Geometry(props:any) {
  return (
    <SvgIcon  {...props}>
    <path d="M16.1251 3.55941V3.53475C16.1147 3.51292 16.1017 3.49265 16.0862 3.47448L16.0707 3.45805C16.0502 3.43672 16.0266 3.41913 16.0006 3.40599L11.105 1.02533C11.0709 1.00864 11.0337 1 10.9961 1C10.9585 1 10.9213 1.00864 10.8872 1.02533L5.99935 3.40599C5.97345 3.41913 5.94979 3.43672 5.92934 3.45805L5.91378 3.47448C5.89833 3.49265 5.88525 3.51292 5.87489 3.53475V3.55941C5.86344 3.58996 5.8573 3.62244 5.85674 3.65529V9.62475L1.14262 11.915C1.11671 11.9281 1.09305 11.9457 1.07261 11.9671L1.05705 11.9835C1.0416 12.0017 1.02852 12.0219 1.01815 12.0438V12.0684C1.00671 12.099 1.00056 12.1315 1 12.1643V18.3365C0.999878 18.3889 1.01398 18.4402 1.04064 18.4844C1.06729 18.5286 1.10539 18.5638 1.1504 18.5858L6.03825 20.9664C6.07238 20.9831 6.10954 20.9918 6.14715 20.9918C6.18477 20.9918 6.22193 20.9831 6.25606 20.9664L10.9961 18.6652L15.7388 20.9747C15.7729 20.9914 15.81 21 15.8477 21C15.8853 21 15.9224 20.9914 15.9566 20.9747L20.8496 18.583C20.8946 18.561 20.9327 18.5259 20.9594 18.4817C20.986 18.4375 21.0001 18.3861 21 18.3337V12.1643C20.9994 12.1315 20.9933 12.099 20.9818 12.0684V12.0438C20.9715 12.0219 20.9584 12.0017 20.943 11.9835L20.9274 11.9671C20.9069 11.9457 20.8833 11.9281 20.8574 11.915L16.1433 9.62475V3.65255C16.1424 3.62063 16.1363 3.5891 16.1251 3.55941ZM5.88526 20.287L1.51601 18.1612V12.5917L5.88526 14.7203V20.287ZM6.14456 14.2409L1.87644 12.1643L6.08492 10.1151L10.353 12.1944L6.14456 14.2409ZM10.7368 18.1776L6.40386 20.287V14.7203L10.7368 12.6109V18.1776ZM10.7368 11.778L6.36756 9.64941V4.08266L10.7368 6.20854V11.778ZM10.9961 5.73186L6.72799 3.65255L10.9961 1.57598L15.2642 3.65255L10.9961 5.73186ZM15.6247 4.08814V9.64941L11.2554 11.778V6.20854L15.6247 4.08814ZM15.5884 20.2953L11.2554 18.1776V12.6081L15.5884 14.7176V20.2953ZM20.4762 18.1666L16.107 20.287V14.7203L20.4762 12.5944V18.1666ZM20.1158 12.1643L15.8477 14.2409L11.6392 12.1944L15.9073 10.1151L20.1158 12.1643Z" fill="none" stroke="currentColor"/> 
    </SvgIcon>
  );
}

