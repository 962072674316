import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default  function XYPloatsIcon(props:any) {
   
    return (

       <SvgIcon {...props}   viewBox="0 0 18 18">

		 <path d="M0 0H1.8V16.2H18V18H0V0ZM6.3 7.2C7.01608 7.2 7.70284 7.48446 8.20919 7.99081C8.71554 8.49716 9 9.18392 9 9.9C9 10.6161 8.71554 11.3028 8.20919 11.8092C7.70284 12.3155 7.01608 12.6 6.3 12.6C5.58392 12.6 4.89716 12.3155 4.39081 11.8092C3.88446 11.3028 3.6 10.6161 3.6 9.9C3.6 9.18392 3.88446 8.49716 4.39081 7.99081C4.89716 7.48446 5.58392 7.2 6.3 7.2ZM9.9 0C10.6161 0 11.3028 0.284464 11.8092 0.790812C12.3155 1.29716 12.6 1.98392 12.6 2.7C12.6 3.41608 12.3155 4.10284 11.8092 4.60919C11.3028 5.11554 10.6161 5.4 9.9 5.4C9.18392 5.4 8.49716 5.11554 7.99081 4.60919C7.48446 4.10284 7.2 3.41608 7.2 2.7C7.2 1.98392 7.48446 1.29716 7.99081 0.790812C8.49716 0.284464 9.18392 0 9.9 0ZM14.4 9C15.1161 9 15.8028 9.28446 16.3092 9.79081C16.8155 10.2972 17.1 10.9839 17.1 11.7C17.1 12.4161 16.8155 13.1028 16.3092 13.6092C15.8028 14.1155 15.1161 14.4 14.4 14.4C13.6839 14.4 12.9972 14.1155 12.4908 13.6092C11.9845 13.1028 11.7 12.4161 11.7 11.7C11.7 10.9839 11.9845 10.2972 12.4908 9.79081C12.9972 9.28446 13.6839 9 14.4 9Z" fill="currentColor"/>

       </SvgIcon>
    )


}