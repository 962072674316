import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';
export default function Transform(props:any) {
    return (
        <SvgIcon  viewBox="0 0 22 22" fill="none" {...props}>
        <path d="M8.8571 1H3.14284C2.57453 1 2.02949 1.21798 1.62762 1.60599C1.22576 1.99399 1 2.52024 1 3.06897V18.931C1 19.4798 1.22576 20.006 1.62762 20.394C2.02949 20.782 2.57453 21 3.14284 21H8.8571C9.42542 21 9.97046 20.782 10.3723 20.394C10.7742 20.006 10.9999 19.4798 10.9999 18.931V3.06897C10.9999 2.52024 10.7742 1.99399 10.3723 1.60599C9.97046 1.21798 9.42542 1 8.8571 1ZM10.2857 18.931C10.2857 19.2969 10.1352 19.6477 9.86724 19.9064C9.59934 20.165 9.23598 20.3103 8.8571 20.3103H3.14284C2.76397 20.3103 2.40061 20.165 2.1327 19.9064C1.86479 19.6477 1.71428 19.2969 1.71428 18.931V3.06897C1.71428 2.70315 1.86479 2.35232 2.1327 2.09365C2.40061 1.83497 2.76397 1.68966 3.14284 1.68966H8.8571C9.23598 1.68966 9.59934 1.83497 9.86724 2.09365C10.1352 2.35232 10.2857 2.70315 10.2857 3.06897V18.931Z" fill="currentColor" stroke="currentColor" strokeWidth="0.7"/>
        <path d="M18.8574 11.345H11.7146C11.6198 11.345 11.529 11.3813 11.462 11.446C11.395 11.5106 11.3574 11.5983 11.3574 11.6898C11.3574 11.7813 11.395 11.869 11.462 11.9336C11.529 11.9983 11.6198 12.0346 11.7146 12.0346H18.8574C19.2363 12.0346 19.5996 12.1799 19.8675 12.4386C20.1354 12.6973 20.2859 13.0481 20.2859 13.4139V18.9312C20.2859 19.297 20.1354 19.6478 19.8675 19.9065C19.5996 20.1652 19.2363 20.3105 18.8574 20.3105H11.7146C11.6198 20.3105 11.529 20.3468 11.462 20.4115C11.395 20.4762 11.3574 20.5639 11.3574 20.6553C11.3574 20.7468 11.395 20.8345 11.462 20.8991C11.529 20.9638 11.6198 21.0001 11.7146 21.0001H18.8574C19.4257 21.0001 19.9707 20.7822 20.3726 20.3942C20.7745 20.0062 21.0002 19.4799 21.0002 18.9312V13.4139C21.0002 12.8652 20.7745 12.339 20.3726 11.951C19.9707 11.563 19.4257 11.345 18.8574 11.345Z" fill="#currentColor" stroke="#currentColor" strokeWidth="0.7"/>
        <mask id="path-3-inside-1" fill="white">
        <path d="M7.07135 3.37939H5.64279C5.54807 3.37939 5.45723 3.41572 5.39025 3.48039C5.32327 3.54506 5.28564 3.63277 5.28564 3.72422C5.28564 3.81568 5.32327 3.90338 5.39025 3.96805C5.45723 4.03272 5.54807 4.06905 5.64279 4.06905H7.07135C7.16607 4.06905 7.25691 4.03272 7.32389 3.96805C7.39086 3.90338 7.42849 3.81568 7.42849 3.72422C7.42849 3.63277 7.39086 3.54506 7.32389 3.48039C7.25691 3.41572 7.16607 3.37939 7.07135 3.37939Z"/>
        </mask>
        <path d="M7.07135 3.37939H5.64279C5.54807 3.37939 5.45723 3.41572 5.39025 3.48039C5.32327 3.54506 5.28564 3.63277 5.28564 3.72422C5.28564 3.81568 5.32327 3.90338 5.39025 3.96805C5.45723 4.03272 5.54807 4.06905 5.64279 4.06905H7.07135C7.16607 4.06905 7.25691 4.03272 7.32389 3.96805C7.39086 3.90338 7.42849 3.81568 7.42849 3.72422C7.42849 3.63277 7.39086 3.54506 7.32389 3.48039C7.25691 3.41572 7.16607 3.37939 7.07135 3.37939Z" fill="#currentColor"/>
        <path d="M7.07135 2.37939H5.64279V4.37939H7.07135V2.37939ZM5.64279 2.37939C5.29395 2.37939 4.95264 2.51286 4.69565 2.76099L6.08485 4.19979C5.96181 4.31859 5.80218 4.37939 5.64279 4.37939V2.37939ZM4.69565 2.76099C4.43747 3.01028 4.28564 3.35601 4.28564 3.72422H6.28564C6.28564 3.90953 6.20908 4.07984 6.08485 4.19979L4.69565 2.76099ZM4.28564 3.72422C4.28564 4.09244 4.43747 4.43817 4.69565 4.68745L6.08485 3.24865C6.20908 3.3686 6.28564 3.53891 6.28564 3.72422H4.28564ZM4.69565 4.68745C4.95264 4.93558 5.29395 5.06905 5.64279 5.06905V3.06905C5.80218 3.06905 5.96181 3.12986 6.08485 3.24865L4.69565 4.68745ZM5.64279 5.06905H7.07135V3.06905H5.64279V5.06905ZM7.07135 5.06905C7.42018 5.06905 7.76149 4.93558 8.01848 4.68745L6.62929 3.24865C6.75233 3.12986 6.91195 3.06905 7.07135 3.06905V5.06905ZM8.01848 4.68745C8.27667 4.43817 8.42849 4.09244 8.42849 3.72422H6.42849C6.42849 3.53891 6.50506 3.3686 6.62929 3.24865L8.01848 4.68745ZM8.42849 3.72422C8.42849 3.35601 8.27667 3.01028 8.01848 2.76099L6.62929 4.19979C6.50506 4.07984 6.42849 3.90953 6.42849 3.72422H8.42849ZM8.01848 2.76099C7.76149 2.51286 7.42018 2.37939 7.07135 2.37939V4.37939C6.91195 4.37939 6.75233 4.31859 6.62929 4.19979L8.01848 2.76099Z" fill="currentColor" mask="url(#path-3-inside-1)"/>
        <path d="M4.63557 3.8625C4.63829 3.86099 4.64099 3.85938 4.64365 3.85766C4.66471 3.84407 4.6838 3.82377 4.69686 3.79739C4.69686 3.79739 4.69686 3.79739 4.69686 3.79739C4.69815 3.79478 4.69938 3.79212 4.70055 3.78939C4.7093 3.769 4.71387 3.74676 4.71387 3.72422C4.71387 3.71303 4.71274 3.70176 4.71045 3.69061C4.70544 3.6663 4.69535 3.64474 4.68207 3.62691C4.67706 3.62018 4.67159 3.61397 4.66577 3.60835C4.64473 3.58804 4.62002 3.57608 4.59564 3.5714C4.58752 3.56984 4.57927 3.56905 4.57101 3.56905C4.55453 3.56905 4.53795 3.57218 4.52201 3.57855L4.57082 3.7006M4.63557 3.8625C4.61484 3.87399 4.59261 3.87939 4.57101 3.87939C4.55991 3.87939 4.54857 3.87795 4.53734 3.87497C4.51582 3.86925 4.49466 3.85787 4.47625 3.84009L4.56086 3.75245L4.5698 3.7432L4.56086 3.75245L4.47625 3.84009C4.4704 3.83445 4.46494 3.82824 4.45995 3.82152C4.44478 3.80113 4.43394 3.77612 4.4299 3.7484C4.42875 3.74055 4.42815 3.73247 4.42815 3.72422C4.42815 3.70732 4.4307 3.69096 4.43539 3.67563C4.44033 3.65952 4.44763 3.64454 4.45681 3.63127C4.46387 3.62106 4.47182 3.61216 4.48035 3.60454C4.49327 3.59298 4.50752 3.58435 4.52201 3.57855L4.57082 3.7006M4.63557 3.8625L4.61731 3.81683L4.63557 3.8625ZM4.63557 3.8625L4.70768 4.0428L4.63557 3.8625ZM4.57082 3.7006L4.57101 3.70106L4.57082 3.7006ZM4.58933 3.72297L4.58933 3.72297L4.58933 3.72297ZM4.58933 3.72297L4.59826 3.71372L4.58933 3.72297ZM4.57254 3.70491L4.57611 3.71383L4.57255 3.70491L4.57254 3.70491Z" fill="currentColor" stroke="currentColor"/>
        <mask id="path-6-inside-2" fill="white">
        <path d="M17.5 17.207C17.5 17.2985 17.5376 17.3862 17.6046 17.4509C17.6716 17.5155 17.7624 17.5519 17.8571 17.5519C17.9519 17.5519 18.0427 17.5155 18.1097 17.4509C18.1767 17.3862 18.2143 17.2985 18.2143 17.207V15.8277C18.2143 15.7363 18.1767 15.6486 18.1097 15.5839C18.0427 15.5192 17.9519 15.4829 17.8571 15.4829C17.7624 15.4829 17.6716 15.5192 17.6046 15.5839C17.5376 15.6486 17.5 15.7363 17.5 15.8277V17.207Z"/>
        </mask>
        <path d="M17.5 17.207C17.5 17.2985 17.5376 17.3862 17.6046 17.4509C17.6716 17.5155 17.7624 17.5519 17.8571 17.5519C17.9519 17.5519 18.0427 17.5155 18.1097 17.4509C18.1767 17.3862 18.2143 17.2985 18.2143 17.207V15.8277C18.2143 15.7363 18.1767 15.6486 18.1097 15.5839C18.0427 15.5192 17.9519 15.4829 17.8571 15.4829C17.7624 15.4829 17.6716 15.5192 17.6046 15.5839C17.5376 15.6486 17.5 15.7363 17.5 15.8277V17.207Z" fill="currentColor"/>
        <path d="M16.5 17.207C16.5 17.5753 16.6518 17.921 16.91 18.1703L18.2992 16.7315C18.4234 16.8514 18.5 17.0217 18.5 17.207H16.5ZM16.91 18.1703C17.167 18.4184 17.5083 18.5519 17.8571 18.5519V16.5519C18.0165 16.5519 18.1762 16.6127 18.2992 16.7315L16.91 18.1703ZM17.8571 18.5519C18.206 18.5519 18.5473 18.4184 18.8043 18.1703L17.4151 16.7315C17.5381 16.6127 17.6977 16.5519 17.8571 16.5519V18.5519ZM18.8043 18.1703C19.0625 17.921 19.2143 17.5753 19.2143 17.207H17.2143C17.2143 17.0217 17.2908 16.8514 17.4151 16.7315L18.8043 18.1703ZM19.2143 17.207V15.8277H17.2143V17.207H19.2143ZM19.2143 15.8277C19.2143 15.4595 19.0625 15.1138 18.8043 14.8645L17.4151 16.3033C17.2908 16.1834 17.2143 16.013 17.2143 15.8277H19.2143ZM18.8043 14.8645C18.5473 14.6164 18.206 14.4829 17.8571 14.4829V16.4829C17.6977 16.4829 17.5381 16.4221 17.4151 16.3033L18.8043 14.8645ZM17.8571 14.4829C17.5083 14.4829 17.167 14.6164 16.91 14.8645L18.2992 16.3033C18.1762 16.4221 18.0165 16.4829 17.8571 16.4829V14.4829ZM16.91 14.8645C16.6518 15.1138 16.5 15.4595 16.5 15.8277H18.5C18.5 16.013 18.4234 16.1834 18.2992 16.3033L16.91 14.8645ZM16.5 15.8277V17.207H18.5V15.8277H16.5Z" fill="currentColor" mask="url(#path-6-inside-2)"/>
        <path d="M17.9682 14.8905L17.8844 14.8037M17.9682 14.8905L17.8844 14.8037M17.9682 14.8905C17.9632 14.8972 17.9577 14.9034 17.9519 14.9091C17.9309 14.9294 17.9062 14.9413 17.8818 14.946C17.8736 14.9476 17.8654 14.9484 17.8571 14.9484C17.8407 14.9484 17.8241 14.9452 17.8081 14.9389C17.7936 14.9331 17.7794 14.9244 17.7665 14.9129C17.758 14.9053 17.75 14.8964 17.7429 14.8861C17.7338 14.8729 17.7265 14.8579 17.7215 14.8418C17.7168 14.8265 17.7143 14.8101 17.7143 14.7932C17.7143 14.7849 17.7149 14.7769 17.716 14.769C17.7201 14.7413 17.7309 14.7163 17.7461 14.6959C17.7511 14.6892 17.7565 14.683 17.7624 14.6773L17.847 14.765L17.7624 14.6773C17.7808 14.6595 17.802 14.6482 17.8235 14.6424C17.8347 14.6395 17.846 14.638 17.8571 14.638C17.8787 14.638 17.901 14.6434 17.9217 14.6549C17.9244 14.6564 17.9271 14.658 17.9298 14.6598C17.9508 14.6733 17.9699 14.6936 17.983 14.72C17.9843 14.7226 17.9855 14.7253 17.9867 14.728C17.9954 14.7484 18 14.7706 18 14.7932C18 14.8044 17.9989 14.8157 17.9966 14.8268C17.9916 14.8511 17.9815 14.8727 17.9682 14.8905ZM17.8844 14.8037L17.8755 14.7944M17.8844 14.8037L17.8755 14.7944M17.8755 14.7944L17.8755 14.7944M17.8755 14.7944L17.8755 14.7944M17.8755 14.7944L17.8743 14.7932L17.8755 14.7944ZM17.8627 14.7812L17.8571 14.7755L17.8627 14.7812Z" fill="currentColor" stroke="currentColor"/>
        </SvgIcon>
    )
}


