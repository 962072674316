import SvgIcon from '@material-ui/core/SvgIcon';

export default function Derived(props:any) {
  return (
    <SvgIcon viewBox='0 0 20 17' { ...props}>
     <path d="M5.49586 10.5552C5.3413 10.5552 5.21484 10.6817 5.21484 10.8363V14.0819C5.21484 14.4613 5.52396 14.7704 5.88929 14.7704H8.02502C8.17958 14.7704 8.30604 14.644 8.30604 14.4894C8.30604 14.3349 8.17958 14.2084 8.02502 14.2084H5.88929C5.81903 14.2084 5.76283 14.1522 5.76283 14.096V10.8362C5.77688 10.6816 5.65042 10.5552 5.49586 10.5552V10.5552Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
<path d="M18.8168 10.7937L15.4587 7.88516C15.3744 7.81491 15.2761 7.7587 15.1637 7.7587C14.9529 7.7587 14.7843 7.94137 14.7983 8.15213V8.18023V9.58532H14.6719C12.2692 9.58532 11.1311 7.56199 11.0749 7.46365C11.0046 7.32314 10.836 7.28099 10.6955 7.35124C10.6112 7.3934 10.555 7.49175 10.555 7.60416C10.5269 9.24813 10.9625 10.5267 11.8336 11.4119C12.8734 12.4797 14.2785 12.6483 14.7983 12.6624V14.208C14.7702 14.4188 14.9248 14.6014 15.1355 14.6295H15.1636C15.2761 14.6295 15.3744 14.5874 15.4587 14.5031L18.8309 11.5524C19.0417 11.3697 19.0557 11.0466 18.8731 10.8358C18.859 10.8358 18.8309 10.8218 18.8169 10.7937L18.8168 10.7937ZM15.3605 13.8427V12.4376C15.3605 12.3393 15.3183 12.255 15.234 12.1988L15.1778 12.1566C15.1356 12.1285 15.0794 12.1145 15.0232 12.1145H14.967C14.686 12.1145 13.2669 12.0583 12.2412 11.0185C11.637 10.4003 11.2857 9.57128 11.1592 8.51745C12.0444 9.54318 13.3231 10.1474 14.6721 10.1474C14.7845 10.1474 14.8969 10.1474 14.9953 10.1333H15.0234L15.1217 10.1193C15.2622 10.0911 15.3606 9.97874 15.3606 9.83823V8.54555L18.3956 11.1731L15.3605 13.8427Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
<path d="M9.43053 8.44741V5.91825C9.43053 5.45455 9.05117 5.0752 8.58748 5.0752H1.84305C1.37936 5.0752 1 5.45455 1 5.91825V8.44741C1 8.9111 1.37936 9.29046 1.84305 9.29046H8.58748C9.05117 9.29046 9.43053 8.9111 9.43053 8.44741ZM1.56204 8.44741V5.91825C1.56204 5.76369 1.68849 5.63723 1.84305 5.63723H8.58748C8.74203 5.63723 8.86849 5.76369 8.86849 5.91825V8.44741C8.86849 8.60197 8.74203 8.72842 8.58748 8.72842H1.84305C1.68849 8.72842 1.56204 8.60197 1.56204 8.44741Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
<path d="M9.4302 14.4895C9.4302 14.6447 9.30437 14.7705 9.14918 14.7705C8.994 14.7705 8.86816 14.6447 8.86816 14.4895C8.86816 14.3343 8.994 14.2085 9.14918 14.2085C9.30437 14.2085 9.4302 14.3343 9.4302 14.4895Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
<path d="M15.051 15.1915V15.6131C15.051 15.7676 14.9246 15.8941 14.77 15.8941H4.37235C4.21779 15.8941 4.09133 15.7676 4.09133 15.6131V9.85205H3.5293V15.6131C3.5293 16.0768 3.90865 16.4561 4.37235 16.4561H14.77C15.2337 16.4561 15.6131 16.0768 15.6131 15.6131V15.0791C15.4725 15.1494 15.318 15.1915 15.1634 15.1915H15.051Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
<path d="M4.0914 1.84296C4.0914 1.6884 4.21785 1.56194 4.37241 1.56194H9.99277V5.21517C9.99277 5.67886 10.3862 6.05822 10.8499 6.05822H15.051V7.19636H15.1634C15.318 7.19636 15.4725 7.23851 15.6131 7.30876V5.76317C15.6131 5.67886 15.585 5.60861 15.5147 5.5524L10.5126 1.07025C10.4564 1.0281 10.4002 1 10.3299 1H4.37235C3.90865 1 3.5293 1.37936 3.5293 1.84305V4.51272H4.09133L4.0914 1.84296ZM10.5548 1.89916L14.5873 5.49619H10.8496C10.6951 5.49619 10.5546 5.36973 10.5546 5.21517L10.5548 1.89916Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
    </SvgIcon>
  );
}