import MuiIconButton from '@material-ui/core/IconButton';
import MuiToggleButton from '@material-ui/lab/ToggleButton';
import Title from '../../../layout/sideBar/sideBarContainer/sideBarHeader/utilComponents/Title';


import SideBarContainer from '../../../layout/sideBar/sideBarContainer';
import {Routes} from "../../../../routes";
import EyeIcon from 'components/icons/eyeIcon';
import EyeSlashIcon from 'components/icons/eyeSlashIcon';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';                                                                                                                                                                                                                                                                                                  

import {useAppDispatch, useAppSelector} from '../../../../store/storeHooks';

import  TreeView from '../../../shared/RcTree/Labels/LabelTreeView';
import { selectCheckedLeafNodes, selectEditableNodeId, selectSelectionPointerId, setEditableNodeId, setSelectionPointerId,handleLabel2DCreation,setIsTitleEditable,setNewTitle } from '../../../../store/sideBar/labelSlice/AllLabelSlice';
import {invertNode, expandNode, selectLabelData ,selectRootIds, setCheckedVisibility, invertCheckedVisibility, checkNode, createLabel, delete3DLabel , selectedLength, createParentLabel, setActiveLabel, handleProbeHeadCreation, handleMeasurementHeadCreation, selectedLeafNodes, reGroupLabel, selectActiveId, handleFaceHeadCreation,setRouterHistory,selectCheckedNodeForLabelType,
  selectSearchHints,
  removeSearchHint, 
  selectPrevSearches,
  updatePrevSearches
} from "../../../../store/sideBar/labelSlice/AllLabelSlice";
import SearchHints from '../../../shared/hintsPanel'
import SearchBox from 'components/shared/searchBox';
import Clear from '../components/shared/ClearIcon';

import {goBack,push} from 'connected-react-router/immutable';

import AddnotesIcon from '../../../icons/labelNotes';
import PanToolIcon from '@material-ui/icons/PanTool';

  import { convertListToTree } from '../../../utils/tree';

import { ReactHTMLElement, useEffect, useRef, useState } from 'react';
import useContainer from '../../../../customHooks/useContainer';
import { Layers, selectActiveLayers , selectWindowMgr, setActiveLayers, setEditMode,selectWindows} from '../../../../store/windowMgrSlice';
import { windowPrefixId } from '../../../../store/sideBar/labelSlice/AllLabelSlice';
import { selectInteractionMode, setLabelInsertionState, selectActiveViewerID } from 'store/appSlice';
import { InteractionMode, setInteractionMode } from 'backend/viewerAPIProxy';

import {undoStack} from "../../../utils/undoStack";

import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { IconButton, Theme } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import SearchIcon from '../components/shared/SearchIcon';
import { TOUR_MENU_NAMES } from 'components/utils/tourMenus';
import { DialogueProps, dialogueState } from 'store/tutorialSlice';
import OptionContainer from '../../../layout/sideBar/sideBarContainer/sideBarFooter/utilComponents/OptionContainer'
import Option from '../../../layout/sideBar/sideBarContainer/sideBarFooter/utilComponents/Option'
import VisibilityOptions from '../components/shared/Footer/Options/VisibilityOption';

import MuiDeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import MuiEditIcon from '@material-ui/icons/EditOutlined';
import GroupIcon from '../../../icons/group'

// Dropdownbutton 


// CustomHooks for Router History

import useRouterHistory from 'customHooks/useRouterHistory';

export enum Label3DType {
 
  DISTANCE = "DISTANCE", 

}
export default function PointToPoint(){

  const dispatch = useAppDispatch();  
  
  const [routerHistory] = useRouterHistory(); 
  const selectedCount = useAppSelector(selectedLength);  
  const interactionMode = useAppSelector(selectInteractionMode);
  const treeDataRedux = useAppSelector(selectLabelData);
  const windows = useAppSelector(selectWindows);
  const treeRootIds = useAppSelector(selectRootIds);
  const checkedNodes = useAppSelector(selectCheckedLeafNodes);
  
  const {roots, expanded} = convertListToTree(treeDataRedux,treeRootIds);
  const [scrollx ,setScrollX] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const prevSearches = useAppSelector(selectPrevSearches);
  const searchHints = useAppSelector(selectSearchHints);
  const headerRef = useRef(null);

  const activeLayer = useAppSelector(selectActiveLayers);
  const viewerId = useAppSelector(selectActiveViewerID);

  const [popOutActiveLabelID , setPopOutActiveLabelID] = useState("");
  const [popOutLabelID , setPopOutLabelID] = useState("");

  let [isPanBtnPressed,setIsPanBtnPressed] = useState(false);

  const [isPressed,setIsPressed] = useState(false);
  const windowMgr = useAppSelector(selectWindowMgr);
  const selectionPointerId : string = useAppSelector(selectSelectionPointerId)
  const selectedLeafNode = useAppSelector(selectedLeafNodes)

  const containerRef = useRef(null);
  const [containerWidth, containerHeight] = useContainer(containerRef,[treeDataRedux]);


  const checkedNodeIds  = useAppSelector(state => selectCheckedNodeForLabelType(state,Label3DType.DISTANCE));
  
  const useMyStyles = makeStyles<Theme>((theme) => ({
    buttonGroup:{
      width:'95%',
      height:'40px'
    },
    
    button:{
      width:'100%',
      borderRightColor:'transparent',
      lineHeight:'5px',
      textTransform:'none',
      justifyContent: "flex-start",
      backgroundColor:theme.palette.accent.primary,
      color:theme.palette.accent.primaryText,
      fontSize:theme.typography.body2.fontSize,
      fontFamily:theme.typography.fontFamily,
      '&:hover': {
        backgroundColor:theme.palette.accent.secondary,
        color:theme.palette.accent.primaryText,
      }
  
    },
    divIcon:{
      display:'inherit',
      alignItems:'inherit',
      justifyContent:'inherit',
      marginLeft:'-10px'
  
    } ,
    divider:{
      position:'absolute',
      right:'0',
      height:'80%',
      border:'1px solid',
      borderLeftColor:theme.palette.text.secondary
    },
    dropdownButton:{
      width:'10%',
      marginLeft:'-1px',
      color:theme.palette.text.secondary,
      '&:hover': {
        backgroundColor:theme.palette.primary.main,
        color: theme.palette.text.primary,
      }
  
    },
    dropdownPaper:{
      width:'95%',
      marginTop:'10px',
      zIndex:99999999
      //backgroundColor:theme.palette.background.paper
    },
    menuItem:{
      color:theme.palette.text.secondary,
      fontSize:theme.typography.body2.fontSize,
      fontFamily:theme.typography.fontFamily,
      '&:hover svg': {
        color: theme.palette.text.primary,
      },
      '&:hover': {
        color: theme.palette.text.primary,
    }
    },
    listItemIcon:{
      minWidth:'35px',
      color:theme.palette.text.secondary,
  
    },
    root: {

      '&.Mui-selected':{
        backgroundColor: `${theme.palette.action.selected} !important`,
        // backgroundColor:"transparent !important",
        borderRadius: "4px",
        color:theme.palette.accent.primaryText,
      },
      '&.MuiIconButton-root':{
        marginTop: '5px',
        padding: '3px'
      }
    },
    icon: {
      height:"20px",
      width:"20px"
    },
    iconTextColor:{
      color:theme.palette.text.secondary,
      '&:hover svg':{
          color:theme.palette.text.primary
      }
  }
  
  }))


  
  const onHandleDeleteButton = () => {
    dispatch(delete3DLabel({ undoable: true ,checkedNodes:checkedNodeIds}));
    setIsPanBtnPressed(!isPanBtnPressed);
  }
  const onHandleRegroup = () => {
    const id = selectedLeafNode[0]
    const pid = treeDataRedux[id].pid;
    const mainPid = treeDataRedux[pid].pid;

    setInteractionMode(viewerId, InteractionMode.DEFAULT); 
    dispatch(setLabelInsertionState(false));

    dispatch(reGroupLabel({selectedNodes : selectedLeafNode, grandPid: mainPid, currentPid:pid, undoable: true}))
  }


  const onHandleShow = () => {
    dispatch(setCheckedVisibility({
      toShow: true,
      leafIds: checkedNodes.map(n => n.id),
      undoable: true,
    }))

  }

  const onHandleHide = () =>{
    dispatch(setCheckedVisibility({
      toShow: false,
      leafIds: checkedNodes.map(n => n.id),
      undoable: true,
    }))
  }

  const onHandleInvert = () =>{
    dispatch(invertCheckedVisibility({
      leafIds: checkedNodes.map(n => n.id),
      undoable: true,
    }))
  }

  const handleExpand = (toOpen: boolean, nodeId: any) => {
    let toOpenval = treeDataRedux[nodeId.node.id].state.expanded
    toOpenval=!toOpenval
    dispatch(expandNode({ toOpen: toOpenval, nodeId: nodeId.node.id }));
  };
  
  useEffect(() => {
    isSetPanChange().then((value) =>{
      if(value){
        Object.values(treeDataRedux).forEach((e) => {
          const uid = windowPrefixId + e.id;
          //checking if leaf node exists and if exits, weather isEdit mode of leaf node is true
          if ((e.children.length === 0) && e.pid && !(['PROBE','DISTANCE','ARC','FACE','LABEL3D','MEASUREMENT','-1'].includes(e.pid))) {
            if(windowMgr.windows[uid]?.isEditMode){
              dispatch(setEditMode({uid,isEdit:false}))
            }//setting window[uid].isEdit value to false when labelList is mounted in-order to handle toggling of "getHeaderRightIcon"
          }
        })
      }
    })
    return () =>{
//     dispatch(setSelectionPointerId(''));
      dispatch(setRouterHistory(routerHistory));
      setInteractionMode(viewerId, InteractionMode.DEFAULT);
      dispatch(setLabelInsertionState(false));
      if(isPressed){console.log("isPressed is active")}
    }
  },[])

  //functiion to check for the existence of leaf nodes
  const isSetPanChange  = async () => {
    let isEditSet = false;
    await Object.values(treeDataRedux).forEach((e) => {
      if ((e.children.length === 0) && e.pid && !(['PROBE','DISTANCE','ARC','FACE','LABEL3D','MEASUREMENT','-1'].includes(e.pid))) {
        isEditSet = true;
      } 
    })
    return isEditSet;
  } 

  useEffect (() => {
    isSetPanChange().then((value) => { if(!value){setIsPressed(false)}})
  },[isPanBtnPressed])

  useEffect ( () => {
    if (!isPressed &&( activeLayer[0] === Layers.FRONT)){
      dispatch(setActiveLayers([Layers.VIEWER]));
    } 
  },[isPressed]);
  useEffect ( () => {
    if (isPressed &&( activeLayer[0] !== Layers.FRONT)){
      setIsPressed(false);
    }

 // new Labelgui

  },[activeLayer]);

  const handlePanChange = async () => {
    let localIsPressed = isPressed;
    Object.values(treeDataRedux).forEach((e) => {
      if ((e.children.length === 0) && e.pid && !(['PROBE','DISTANCE','ARC','FACE','LABEL3D','MEASUREMENT','-1'].includes(e.pid))) {
        dispatch(setEditMode({
          uid: windowPrefixId + e.id,
          isEdit: !localIsPressed
        }));
      }
    })

    const isToggleEnable = await isSetPanChange()
    if(isToggleEnable){
      setIsPressed(!isPressed);
      dispatch(setSelectionPointerId(''))
      setInteractionMode(viewerId, InteractionMode.DEFAULT);
      dispatch(setLabelInsertionState(false));
    }
  }
  const onClickSearchIcon = () => {
    setIsSearchMode(true);
  }

  const getHeaderContent = () => {

    return (isSearchMode ?
      <SearchBox 
      placeholder="Search" 
      text={searchText} 
      onChange={handleSearchTextChange}
      searchPool={treeDataRedux}
      onClear={() => {}}
      
      getAttribKeys={(data) => ["title"]}
  
      />
      : <Title text="Point to Point" group="Labels"/>)
  }

  const getHeaderRightIcon = () => {
    return (
      isSearchMode ?
      <div style={{display: "inherit"}}>
      <Clear onClick={() => setIsSearchMode(false)}/>
      <IconButton onClick={(event) =>handleResultsClick(event)}><HelpIcon/>
      </IconButton></div>
      : 
          
      <div style={{display: "inherit"}}>
      <SearchIcon onClick={onClickSearchIcon} />
      <IconButton onClick={(event) =>handleResultsClick(event)}><HelpIcon/>
      </IconButton>
      </div>
    );
  };

  const handleResultsClick = (e: any) => {
    dispatch(dialogueState(dialogProps));
  };

  const generateOptions = () => {
    let options:any = {};
    prevSearches.forEach((e:string) => {
        options[e] = Object.keys(options).length;
    })
    searchHints.forEach((e:string) => {
        options[e] = Object.keys(options).length;
    })
    return Object.keys(options) as string[]
  }
  const handleHintsClick = (s:string) => {
      setSearchText(s);
  }
  const handleHintsDelete = (s:string) => {
      dispatch(removeSearchHint({data:s}));
  }
  const handleSearchResult = (results:any[]) => {
    let filtered = results.map(result => {
        let node =  JSON.parse(JSON.stringify(result.item));
        if(result.matches) node.matches =result.matches
        return node
      }
      );
    setSearchResults(filtered);
  }
  const handleSearchTextChange = (s:string, r: any[]) => {
    setSearchText(s);
    setSearchResults(r);
    handleSearchResult(r);
  }

  useEffect(() => {
    if (!isSearchMode){
      dispatch(updatePrevSearches(searchText))
    }
  },[isSearchMode, searchResults, treeDataRedux])

  const handleAdd = () => {
  dispatch(handleMeasurementHeadCreation({pid : Label3DType.DISTANCE, undoable: true }))
      setInteractionMode(viewerId, InteractionMode.DEFAULT);
      dispatch(setLabelInsertionState(false));
  }

  const handleInvert = (node:ITreeNode, undoable?:boolean) => {
    dispatch(invertNode({nodeId:node.id}));
    if(undoable){
      undoStack.add(
        {
          undo: () => handleInvert(node),
          redo: () => handleInvert(node),
        }
      )
    }
    
  }
  
  const handleCheck = (toCheck:boolean, nodeId:string, undoable?:boolean) => {
    dispatch(checkNode({toCheck,nodeId}));

    if(undoable){
      undoStack.add(
        {
          undo: () => handleCheck(!toCheck, nodeId),
          redo: () => handleCheck(toCheck, nodeId),
        }
      )
    }
  }
  const dataPointtopoint = roots.filter((treeData:any) => {
    return treeData.pid === 'DISTANCE';
  });
  const handleVisibility = (toShow:boolean,node:ITreeNode,undoable?:boolean) => {
    const leafIds = [node.id];
    const pids = [node.pid];

    dispatch(setCheckedVisibility({toShow, leafIds}));

    if(undoable){
      undoStack.add(
        {
          undo: () => handleVisibility(!toShow, node),
          redo: () => handleVisibility(toShow, node),
        }
      )
    }
  }

  const handleSelectPoints = (node:any) => {
    // const node = treeDataRedux[activeLabelId]

    // setSelectToggle(!selectToggle)
    // selectionPointerId === node.id ? dispatch(setSelectionPointerId('')) : dispatch(setSelectionPointerId(node.id))
    // if(isPressed){handlePanChange()}


    // if(node.pid === Label3DType.DISTANCE){
    //   let mode = interactionMode !== InteractionMode.LABEL_MEASUREMENT_POINT_TO_POINT ? InteractionMode.LABEL_MEASUREMENT_POINT_TO_POINT : InteractionMode.DEFAULT;
    //   setInteractionMode(viewerId, mode);
    //   dispatch(setLabelInsertionState(interactionMode !== InteractionMode.LABEL_MEASUREMENT_POINT_TO_POINT));
    // }

    dispatch(setSelectionPointerId(node.id));

    dispatch(push(Routes.SELECT_WINDOW));


    
  }

  let dialogProps:DialogueProps={
    dialogueRun: true,
    tourName: TOUR_MENU_NAMES.LABELS
  }

  const onHandleEdit=(id:string)=> {

    dispatch(setEditableNodeId(id));
     dispatch(push(Routes.All_LABELS_EDIT));
  }

  
  const onHandlePopOut=(id:string)=> {

    setPopOutActiveLabelID(id);

    let popOutLabel:boolean= true;

    Object.values(treeDataRedux).forEach((e) => {

      if(id === e.id) {

            setPopOutLabelID(id);

            if(popOutLabelID === e.id) {

              popOutLabel = false ;
              dispatch(setActiveLayers([Layers.VIEWER]));
               setPopOutLabelID("");
            }
            else {

             dispatch(setActiveLayers([Layers.FRONT]));
              popOutLabel = true ;
            }

            dispatch(setEditMode({
              uid: windowPrefixId + id,
              isEdit: popOutLabel
            }));

      }
      else {

          popOutLabel = false ;

          dispatch(setEditMode({
            uid: windowPrefixId + e.id,
            isEdit: popOutLabel
          }));

      }

    }) 

  }
  
 // New Label Gui 

 useEffect(()=>{

  Object.values(windows).forEach((item)=> {

    let id = windowPrefixId + popOutActiveLabelID;
  
    if(id === item.id) {
  
          item.isEditMode ? setPopOutLabelID(popOutActiveLabelID) : setPopOutLabelID('');
    }
  
  })

 },[activeLayer])

 const onHandleIsTitleEditable = (nodeId:string,editable:boolean) => {
  dispatch(setIsTitleEditable({nodeId:nodeId,isEditable:editable}));
}
const setNewTitleTitle = (nodeId:string,newTitle:string)=> {
  dispatch(setNewTitle({nodeId:nodeId,newTitle:newTitle}));
}
  
  const getBody = () => {

    return (
      <div style={{marginTop:'10px'}} ref = {containerRef}>

      <div ref = {headerRef} >
                {
                    isSearchMode ?
                    <SearchHints data = {generateOptions()} onClick={handleHintsClick} onDelete={handleHintsDelete}></SearchHints>
                    :null
                }
                </div>
        
        <div style={{display:"flex",justifyContent:"center"}}>
          <ButtonGroup className={classes.buttonGroup} variant="contained" color="primary"  aria-label="split button">
          <Button className={classes.button} onClick={()=>handleAdd()} >
          <div className={classes.divIcon}>
            <AddnotesIcon fontSize="small"/>
          </div>
          <div style={{marginLeft:'10px'}}>Add Point to Point</div> 
          </Button>
          </ButtonGroup>
        </div>

        <TreeView 
        treedata={dataPointtopoint}  
        nodes={treeDataRedux} 
        labelIcon={false}
        containerwidth={containerWidth}
        check={handleCheck}  
        invert={handleInvert}
        expanded={expanded}
        handleExpand={handleExpand}
        visibility={handleVisibility} 
        selectionpoint={handleSelectPoints} 
        popout={onHandlePopOut}
        edit={onHandleEdit}
        isTitleEditable = {true}
        onHandleIsTitleEditable = {onHandleIsTitleEditable}
        setNewTitleTitle = {setNewTitleTitle}
        scrollValue={scrollx}
        searchtext={searchText}
        searchmode={isSearchMode}
      />
      </div>
    )
  }

  const classes = useMyStyles();

  const getFooter = () => {


    return(
        
        <div>
          <OptionContainer>
          

            <Option label='Show' active={selectedCount < 1} icon={EyeIcon}  onClickUpdate ={onHandleShow}/>
            <Option label='Hide' active={selectedCount < 1} icon={EyeSlashIcon} onClickUpdate ={onHandleHide}/>
            <Option
            id="visibilityInvert"
            label="Invert"
            active={selectedCount < 1}
            icon={EyeSlashIcon}
            onClickUpdate={onHandleInvert}
          />
            <Option label="Delete" active={selectedCount >= 1? false : true} icon={MuiDeleteForeverOutlinedIcon} onClickUpdate={onHandleDeleteButton} />

            </OptionContainer>
      </div>
    
    ) 
  }

  return (
          <SideBarContainer
            headerContent={ getHeaderContent() }
            headerRightIcon = { getHeaderRightIcon() }
            body ={ getBody() }
            footer = { getFooter() }
          />

  )
}
