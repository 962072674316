import SvgIcon from '@material-ui/core/SvgIcon';

export default function NextSlide(props:any) {
  return (
    <SvgIcon  viewBox="0 0 20 20" {...props}>
    <path d="M18.7749 6.32382H16.8972V3.88677C16.8972 3.76246 16.7966 3.6617 16.6721 3.6617H14.7944V1.22509C14.7944 1.10079 14.6938 1.00003 14.5693 1.00003H1.22507C1.10076 1.00003 1 1.10063 1 1.22509V13.4513C1 13.5756 1.1006 13.6764 1.22507 13.6764H3.1028V16.1133C3.1028 16.2376 3.2034 16.3384 3.32786 16.3384H5.20559V18.775C5.20559 18.8993 5.3062 19 5.43066 19H18.7749C18.8992 19 19 18.8994 19 18.775V6.54872C19 6.42442 18.8994 6.32381 18.7749 6.32381V6.32382ZM1.44972 1.4502H14.3444V13.2264H1.44972V1.4502ZM3.55253 13.6764H14.5692C14.6935 13.6764 14.7943 13.5758 14.7943 13.4514V4.11194H16.4471V15.8882H3.55247L3.55253 13.6764ZM18.5502 18.5501H5.6555V16.3384H16.6722C16.7965 16.3384 16.8973 16.2378 16.8973 16.1133V6.77387H18.5501L18.5502 18.5501Z" fill="currentColor" stroke="currentColor"/>
<path d="M6 10.1717L9.09042 7.50003L6 4.82253L6.95142 4.00003L11 7.50003L6.95142 11L6 10.1717Z" fill="currentColor"/>
</SvgIcon>
  );
}

