
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ColorMap(props:any) {
  return (
    <SvgIcon  {...props}>
   <path d="M17.957 10.1299C17.957 9.99599 17.9067 9.86212 17.8061 9.75503L9.7583 1.16064C9.5571 0.946452 9.25531 0.946452 9.05411 1.16064L5.2817 5.14994C5.25655 5.14994 5.25655 5.14994 5.2314 5.14994H2.49011C1.71047 5.14994 1.08174 5.87283 1.08174 6.78314C1.08174 7.58635 1.58472 8.25569 2.21346 8.38956L1.88652 8.73762C0.704494 9.99599 0.704494 12.0308 1.88652 13.2892L6.41342 18.1084C6.99186 18.7242 7.77149 19.0455 8.55112 19.0455C9.33076 19.0455 10.0852 18.7242 10.6888 18.1084L17.8061 10.5315C17.9067 10.4244 17.957 10.2905 17.957 10.1299ZM8.57627 17.9746C8.04813 17.9746 7.54515 17.7604 7.14275 17.3588L2.61585 12.5395C2.23861 12.1379 2.03742 11.6024 2.03742 11.0134C2.03742 10.4511 2.23861 9.91567 2.61585 9.48729L3.62183 8.41634H10.6134C10.7894 8.81794 11.1667 9.08568 11.6194 9.08568C12.2481 9.08568 12.7511 8.5502 12.7511 7.88086C12.7511 7.21152 12.2481 6.67604 11.6194 6.67604C11.1918 6.67604 10.8146 6.94378 10.6134 7.34539H4.65296L9.40621 2.28514L16.775 10.1299L9.98464 17.332C9.6074 17.7336 9.10441 17.9746 8.57627 17.9746ZM2.49011 7.34539C2.26376 7.34539 2.08771 7.07765 2.08771 6.78314C2.08771 6.48862 2.26376 6.22089 2.49011 6.22089H4.27572L3.21944 7.34539H2.49011Z" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.4537 13.1111C18.177 13.5127 15.7124 16.9666 15.7124 18.6533C15.7124 20.5007 17.1208 22 18.8561 22C20.5914 22 21.9998 20.5007 21.9998 18.6533C21.9998 16.9666 19.5351 13.486 19.2585 13.1111C19.0573 12.8702 18.6297 12.8702 18.4537 13.1111ZM18.8561 20.9559C17.6741 20.9559 16.7184 19.9385 16.7184 18.6801C16.7184 17.7966 17.9004 15.7617 18.8561 14.3695C19.8118 15.7885 20.9938 17.8233 20.9938 18.6801C20.9938 19.9385 20.0381 20.9559 18.8561 20.9559Z" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
}
