import { createSlice, PayloadAction, createAsyncThunk, current } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { TreeNode, ITreeState } from "./shared/Tree/types";
import nextId from "react-id-generator";
import {
  saveTreeReducer,
  checkNodeReducer,
  highlightNodeReducer,
  invertNodeReducer,
  expandNodeReducer,
  toggleVisibilityReducer,
  setCheckedVisibilityReducer,
} from "./shared/Tree/reducers";
import viewpointSlice, {  saveViewpoint } from "store/viewpointSlice";
import { LeafCounter, RootCounter } from "components/utils/counter";

// Define a type for the slice state
export enum SlideType {
  GROUP = 0,
  VIEW = 1,
}

interface SlideTreeNode extends TreeNode {
  downloaded: boolean;
  size: number;
  slideType: SlideType;
  thumbnailData?: string;
  isTitleEditable:boolean
}

interface SlideTreeState extends ITreeState {
  data: { [id: string]: SlideTreeNode };
  rootIds: string[];
  appliedSlide: string;
  selectedSlide: string;
  viewpathCounter: number;
  viewpointCounter: number;
}

const tempdata: any[] = [];

// Define the initial state using that type
const initialState: SlideTreeState = {
  data: {},
  rootIds: [], //["0", "1", "2"],
  appliedSlide: "-1",
  selectedSlide: "-1",
  viewpathCounter: 0,
  viewpointCounter: 0,
};

export const pasteGroup = createAsyncThunk(
  "slide/saveViewpointData",
  async (vpdata: any, { dispatch, getState }) => {
    dispatch(pasteSlide(vpdata));
    if (tempdata) tempdata.forEach((e) => dispatch(saveViewpoint(e)));
  }
);

export const slideSlice = createSlice({
  name: "slide",
  initialState,
  reducers: {
    saveTree: saveTreeReducer,
    checkNode: checkNodeReducer,
    highlightNode: highlightNodeReducer,
    invertNode: invertNodeReducer,
    expandNode: expandNodeReducer,
    toggleVisibility: toggleVisibilityReducer,
    setCheckedVisibility: setCheckedVisibilityReducer,

    setSlideSelection: (state, action: PayloadAction<string>) => {
      if (state.selectedSlide === action.payload) state.selectedSlide = "-1";
      else state.selectedSlide = action.payload;
    },

    setSelectedSlideId: (state, action) => {
      state.selectedSlide = action.payload;
    },

    createNode: (
      state,
      action: PayloadAction<{
        pid: string;
        id: string;
        options: {
          thumbnailData?: string;
        };
      }>
    ) => {
      const { pid, id, options } = action.payload;
      let newData: SlideTreeNode = {
        id,
        pid,
        title: "Slide Group",
        children: [],
        state: {
          expanded: true,
          visibility: true,
        },
        downloaded: true,
        thumbnailData: options.thumbnailData,
        size: 200,
        slideType: pid === "-1" ? SlideType.GROUP : SlideType.VIEW,
        attributes: {},
        isTitleEditable:false
      };

      switch (newData.slideType) {
        case SlideType.GROUP:
          state.viewpathCounter++;
          newData.title = `Slide Group ${RootCounter(current(state))}`;
          newData.size = 0;
          state.data[newData.id] = newData;
          state.rootIds.push(newData.id);
          break;

        default:
          state.viewpointCounter++;
          newData.title = `Slide ${LeafCounter(current(state),pid)}`;
          newData.slideType = SlideType.VIEW;
          state.data[newData.id] = newData;
          state.data[pid].children.push(newData.id);
          break;
      }

      if (pid !== "-1")
        slideSlice.caseReducers.downloadParentFolder(state, {
          payload: state.data[pid].id,
          type: "slideSlice/downloadParentFolder",
        });

      expandNode({ toOpen: true, nodeId: newData.id });
    },

    downloadFile: (state, action: PayloadAction<string>) => {
      state.data[action.payload].downloaded = true;
      slideSlice.caseReducers.downloadParentFolder(state, {
        payload: state.data[action.payload]
          ? state.data[action.payload].pid
          : "-1",
        type: "slideSlice/downloadParentFolder",
      });
    },

    downloadParentFolder: (state, action: PayloadAction<string>) => {
      const downloadParentFolderFunction = (pId: string) => {
        if (pId !== "-1") {
          const parentId = state.data[pId].id;
          const parentChildren = state.data[parentId ? parentId : -1].children;
          let downloadedCount = 0;

          let folderSize = 0;

          parentChildren.forEach((item) => {
            if (state.data[item].downloaded === true) downloadedCount++;

            if (state.data[item].downloaded === false)
              folderSize = folderSize + state.data[item].size;
          });

          state.data[parentId].size = folderSize;

          if (parentChildren.length === downloadedCount)
            state.data[parentId ? parentId : -1].downloaded = true;
          else state.data[parentId ? parentId : -1].downloaded = false;

          if (state.data[parentId].pid !== "-1") {
            const newPId = state.data[parentId].pid;
            downloadParentFolderFunction(newPId ? newPId : "-1");
          }
        }
      };

      downloadParentFolderFunction(action.payload);
    },

    applyView: (state, action: PayloadAction<string>) => {
      state.appliedSlide = action.payload;
    },

    updateSlideData: (state, action: PayloadAction<any>) => {
      state.data = action.payload.data;
      state.rootIds = action.payload.rootIds;
      state.appliedSlide = action.payload.appliedSlide;
      state.selectedSlide = action.payload.selectedSlide;
      state.viewpathCounter = action.payload.viewpathCounter;
      state.viewpointCounter = action.payload.viewpointCounter;
    },

    replaceViewData: (state, action: PayloadAction<string>) => {
      state.data[action.payload].downloaded = false;

      if (state.data[action.payload].id === state.selectedSlide)
        state.appliedSlide = "-1";

      slideSlice.caseReducers.downloadParentFolder(state, {
        payload: state.data[action.payload]
          ? state.data[action.payload].pid
          : "-1",
        type: "slideSlice/downloadParentFolder",
      });
    },

    deleteNode: (state, action: PayloadAction<string>) => {
      const toDelete = state.data[action.payload];
      state.selectedSlide = "-1";
state.viewpointCounter--;
      if (toDelete.slideType === SlideType.VIEW) {
        delete state.data[action.payload];
        Object.keys(state.data).forEach((key) => {
          state.data[key].children = state.data[key].children.filter(
            (item) => item !== action.payload
          );
        });
      }

      const deleteGroup = (id: string) => {
        const children = state.data[id].children;
        children.forEach((item) => {
          if (state.data[id].slideType === SlideType.VIEW)
            delete state.data[id];
          else deleteGroup(item);
        });

        if (state.data[id].pid === "-1") {
          delete state.data[id];
          state.rootIds = state.rootIds.filter((item) => item !== id);
        } else {
          delete state.data[id];
          state.appliedSlide="-1";
          Object.keys(state.data).forEach((key) => {
            state.data[key].children = state.data[key].children.filter(
              (item) => item !== id
            );
          });
        }
      };

      if (toDelete.slideType === SlideType.GROUP) {
        deleteGroup(action.payload);
      }

      slideSlice.caseReducers.downloadParentFolder(state, {
        payload: toDelete.pid,
        type: "slideSlice/downloadParentFolder",
      });
    },

    pasteSlide: (
      state,
      action: PayloadAction<{
        copied: SlideTreeNode;
        pid: string;
        newId: string;
        viewPointsData?: any;
      }>
    ) => {
      let copiedSlideData = JSON.parse(JSON.stringify(action.payload.copied));

      const copyPasteGroup = (
        toCopiedGroupData: any,
        pid: string,
        id: string
      ) => {

        const toCopiedChildId = toCopiedGroupData.children;
        const toCopiedChildren: any[] = [];

        toCopiedChildId.forEach((item: string) => {
          toCopiedChildren.push(JSON.parse(JSON.stringify(state.data[item])));
        });

        toCopiedGroupData.children = [];
        state.viewpathCounter++;
        toCopiedGroupData.id = id;
        toCopiedGroupData.pid = pid;
        toCopiedGroupData.title = `Slide Group ${state.rootIds.length + 1}`;
        toCopiedGroupData.state.expanded = false;
        copiedSlideData.downloaded = true;

        // console.log("cp[",toCopiedGroupData )

        if (pid === "-1") {
          state.data[id] = toCopiedGroupData;
          state.rootIds.push(toCopiedGroupData.id);
        } else {
          state.data[id] = toCopiedGroupData;
          state.data[pid].children.push(toCopiedGroupData.id);
        }

        toCopiedChildren.forEach((item) => {
          if (item.slideType === SlideType.VIEW) {
            state.viewpointCounter++;
            let selectedViewPoint = action.payload.viewPointsData.find(
              (e) => e.uid === item.id
            );
            let slideId = nextId("slide-leaf-");
            while(state.data[slideId]){slideId = nextId("slide-leaf-")}
            let objCopy = { ...selectedViewPoint };
            objCopy.uid = slideId;
            item.id = slideId;
            item.title = `Slide ${state.data[toCopiedGroupData.id].children.length + 1}`;
            item.pid = toCopiedGroupData.id;
            item.isTitleEditable = false;
            item.downloaded = true;
            tempdata.push(objCopy);
            state.data[item.id] = item;
            state.data[item.pid].children.push(item.id);
          }

          if (item.slideType === SlideType.GROUP) {
            state.viewpathCounter++;
            pid = toCopiedGroupData.id;
            copyPasteGroup(item, pid, `${state.viewpathCounter}`);
            slideSlice.caseReducers.downloadParentFolder(state, {
              payload: pid,
              type: "slideSlice/downloadParentFolder",
            });
          }
        });
      };

      if (copiedSlideData.slideType === SlideType.GROUP) {
        copyPasteGroup(
          copiedSlideData,
          action.payload.pid,
          action.payload.newId
        );
        slideSlice.caseReducers.downloadParentFolder(state, {
          payload: action.payload.pid,
          type: "slideSlice/downloadParentFolder",
        });
      }

      if (copiedSlideData.slideType === SlideType.VIEW) {
        if (state.selectedSlide !== "-1") {
          // state.viewpathCounter += 1;
          state.viewpointCounter += 1;
          copiedSlideData.id = action.payload.newId;
          copiedSlideData.title = `Slide ${state.data[action.payload.pid].children.length + 1}`;
          copiedSlideData.downloaded = true;
          copiedSlideData.pid = action.payload.pid;
          copiedSlideData.isTitleEditable = false;
          state.data[copiedSlideData.id] = copiedSlideData;
          state.data[copiedSlideData.pid].children.push(copiedSlideData.id);

          slideSlice.caseReducers.downloadParentFolder(state, {
            payload: action.payload.pid,
            type: "slideSlice/downloadParentFolder",
          });
        }
      }
    },

    setIsTitleEditable:(state,action:PayloadAction<{nodeId:string,isEditable:boolean}>)=> {
      const {nodeId,isEditable} = action.payload;
      state.data[nodeId].isTitleEditable = isEditable;
    },

    setNewTitle:(state,action:PayloadAction<{nodeId:string,newTitle:string}>)=>{ 
      const {nodeId,newTitle} = action.payload;
      state.data[nodeId].title = newTitle;

    }
  },
});

//Define the Reducers
export const {
  updateSlideData,
  saveTree,
  checkNode,
  invertNode,
  expandNode,
  setSlideSelection,
  setSelectedSlideId,
  createNode,
  applyView,
  replaceViewData,
  deleteNode,
  pasteSlide,
  downloadFile,
  downloadParentFolder,
  setIsTitleEditable,
  setNewTitle

} = slideSlice.actions;

//Define the selectors
export const selectSlideData = (state: RootState) => state.slide.data;
export const selectRootIds = (state: RootState) => state.slide.rootIds;
export const selectAppliedSlide = (state: RootState) =>  state.slide.appliedSlide;
export const selectSelectedSlide = (state: RootState) =>  state.slide.selectedSlide;
export const selectViewpathCounter = (state: RootState) => state.slide.viewpathCounter;
export const selectViewpointCounter = (state: RootState) =>  state.slide.viewpointCounter;

export default slideSlice.reducer;
