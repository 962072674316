import SvgIcon from '@material-ui/core/SvgIcon';

export default function DisplayModes(props:any) {
  return (
    <SvgIcon viewBox='0 0 18 18' { ...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.67987 16.0016H2.64703C2.35483 16.0016 2.11765 15.7775 2.11765 15.5015V2.49852C2.11765 2.22248 2.35482 1.99843 2.64703 1.99843H2.67987C3.26548 1.99843 3.73874 1.55033 3.73874 0.998282H3.75563C3.75563 0.4491 3.20616 0.00604415 2.62481 0C1.17428 0.0110515 0 1.12424 0 2.49852V15.5015C0 16.8758 1.17428 17.9891 2.62481 18C3.20616 17.994 3.75563 17.5509 3.75563 17.0018H3.73874C3.73874 16.4496 3.26548 16.0016 2.67987 16.0016ZM15.3201 16.0016H15.353C15.6452 16.0016 15.8824 15.7775 15.8824 15.5015V2.49852C15.8824 2.22248 15.6452 1.99843 15.353 1.99843H15.3201C14.7345 1.99843 14.2613 1.55033 14.2613 0.998282H14.2444C14.2444 0.4491 14.7949 0.00604415 15.3752 0C16.8268 0.0110515 18 1.12424 18 2.49852V15.5015C18 16.8758 16.8268 17.9891 15.3752 18C14.7949 17.994 14.2444 17.5509 14.2444 17.0018H14.2613C14.2613 16.4496 14.7345 16.0016 15.3201 16.0016ZM13.1443 6.74662L10.8562 4.5852C10.458 4.21007 9.92015 3.99911 9.35796 3.99911H6.88246C5.42237 3.99911 4.23546 5.12033 4.23546 6.49962V11.5005C4.23546 12.8798 5.42237 14.001 6.88246 14.001H11.1179C12.578 14.001 13.7649 12.8798 13.7649 11.5005V8.16176C13.7649 7.63065 13.5415 7.12255 13.1445 6.74643L13.1443 6.74662ZM11.6471 11.5006C11.6471 11.7766 11.4099 12.0007 11.1177 12.0007H6.88225C6.59005 12.0007 6.35287 11.7767 6.35287 11.5006V6.49971C6.35287 6.22368 6.59004 5.99962 6.88225 5.99962H8.47051V8.00008C8.47051 8.55013 8.94698 9.00023 9.52925 9.00023H11.6469L11.6471 11.5006Z" fill="currentColor"/>
    </SvgIcon>
  );
}


