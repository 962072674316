import { TourData } from "../tour/Types";
const classPrefix = "#getting-started";

const DATA:TourData = {
  id:'assembly_tree',
  title: 'Assembly Tree',
  description: 
    'This tutorial walks you though the overall GUI ' +
    'layout of Assembly Tree. It is intended ' +
    'to help new users to quickly accustomed to and ' +
    'onboard VCollab Enterprise application.',
  steps: [
    {
      target: "#vertical-tab-AssemblyTree",
      title: "AssemblyTree",
      description:"User can select any menu item or group from the displayed list",
      disableScrolling:true,
      actions: [
        <div>Click on "AssemblyTree".</div>,
        ],
    },
    {
      target: classPrefix + "-step6",
      title: "Assembly Tree Side Bar",
      description:
      "Based on the Selection of the names in the list" +
      " the footer id opened which contains crossesponding the "+
      " corresponding actions for the selected item.",
      actions: [
        <div>Select the "Bracket" in the list.</div>,
        ],
    }, 
    {
      target: '#step20',
      title: "Assembly Tree Footer",
      description:
      " This footer consists of icons that are supposed to do" +
      " actions of corresponding selected item. ",
      placement:'bottom',
    },
    {
      target: '#step22',
      title: "Assembly Tree Footer",
      description:
      " Hear is the Visibility Icon performs the actions of show," +
      " Hide, and the invert the item in the 3D Model. ",
      placement:'bottom',
      actions: [
        <div>Click on "Visibility".</div>,
        ],
    },
    {
      target: '#step23',
      title: "Assembly Tree Footer",
      description:
      " Hear is the Visibility Icon performs the actions of show," +
      " Hide, and the invert the item in the 3D Model. ",
    },
    {
      target: '#step25',
      title: "Assembly Tree Footer",
      description:
      " Hear is the Label Icon performs the actions of entering," +
      " a tag name to the selected nodes. User can easily filter " +
      " this tag name at the time of search",
      placement:'bottom',
      actions: [
        <div>Click on "Label".</div>,
        ],
    },
    {
      target: '#step26',
      title: "Assembly Tree Footer",
      description:
      " Hear is the Label Icon performs the actions of entering," +
      " a tag name to the selected nodes. User can easily filter " +
      " this tag name at the time of search",
      placement:'bottom',
      actions: [
        <div>Click on "Cancel".</div>,
        ],
    },
    {
      target: '#step27',
      title: "Assembly Tree Footer",
      description:
      " Hear is the Fit View Icon performs the actions of ," +
      " fitting the selected item to the 3D model. ",
      placement:'bottom',
      actions: [
        <div>Click on "Fit View".</div>,
        ],
    },
    {
      target: '.getting-started-step2',
      title: "Result on 3D Model",
      placement: 'bottom',
      description:
      " Hear is the Fit View Icon performs the actions of ," +
      " fitting the selected item to the 3D model. ",
    },
  ],
};
export {DATA};
