import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default  function LogoutIcon(props:any) {
    return (
        <SvgIcon viewBox="0 0 20 13">
           <path d="M9.84889 11.1911L12.1511 8.88889H3.55556V7.11111H12.1511L9.84889 4.80889L11.1111 3.55556L15.5556 8L11.1111 12.4444L9.84889 11.1911ZM14.2222 0C14.6937 0 15.1459 0.187301 15.4793 0.520699C15.8127 0.854097 16 1.30628 16 1.77778V5.92889L14.2222 4.15111V1.77778H1.77778V14.2222H14.2222V11.8489L16 10.0711V14.2222C16 14.6937 15.8127 15.1459 15.4793 15.4793C15.1459 15.8127 14.6937 16 14.2222 16H1.77778C0.791111 16 0 15.2 0 14.2222V1.77778C0 0.791111 0.791111 0 1.77778 0H14.2222Z" fill="currentColor"/>
        </SvgIcon>
        
        )

}