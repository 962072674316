import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DownArrow(props:any) {
	
	return (
	
		<SvgIcon width="12" height="8" fontSize="inherit" viewBox="0 0 12 8">
			<path d="M1.41 0L6 4.94879L10.59 0L12 1.531L6 8L0 1.531L1.41 0Z" fill="currentColor"/>
		</SvgIcon>
	
	)
}






















