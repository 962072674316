import SvgIcon from '@material-ui/core/SvgIcon';

export default function ClipPlaneList(props:any) {
  return (
    <SvgIcon viewBox='0 0 22 22' { ...props}>
    <path d="M4.5707 4.57129H1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.57129 1V17.4287H20.9999" fill='none' stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.4285 14.5714V4.57129H7.42773" fill='none' stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.4287 21.0001V17.4287" fill='none' stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.94595 8.2H15V9H8.94595V8.2ZM8.94595 11.4V10.6H15V11.4H8.94595ZM7.64865 8C7.82068 8 7.98567 8.06321 8.10731 8.17574C8.22896 8.28826 8.2973 8.44087 8.2973 8.6C8.2973 8.75913 8.22896 8.91174 8.10731 9.02426C7.98567 9.13679 7.82068 9.2 7.64865 9.2C7.47662 9.2 7.31163 9.13679 7.18998 9.02426C7.06834 8.91174 7 8.75913 7 8.6C7 8.44087 7.06834 8.28826 7.18998 8.17574C7.31163 8.06321 7.47662 8 7.64865 8V8ZM7.64865 10.4C7.82068 10.4 7.98567 10.4632 8.10731 10.5757C8.22896 10.6883 8.2973 10.8409 8.2973 11C8.2973 11.1591 8.22896 11.3117 8.10731 11.4243C7.98567 11.5368 7.82068 11.6 7.64865 11.6C7.47662 11.6 7.31163 11.5368 7.18998 11.4243C7.06834 11.3117 7 11.1591 7 11C7 10.8409 7.06834 10.6883 7.18998 10.5757C7.31163 10.4632 7.47662 10.4 7.64865 10.4V10.4ZM8.94595 13.8V13H15V13.8H8.94595ZM7.64865 12.8C7.82068 12.8 7.98567 12.8632 8.10731 12.9757C8.22896 13.0883 8.2973 13.2409 8.2973 13.4C8.2973 13.5591 8.22896 13.7117 8.10731 13.8243C7.98567 13.9368 7.82068 14 7.64865 14C7.47662 14 7.31163 13.9368 7.18998 13.8243C7.06834 13.7117 7 13.5591 7 13.4C7 13.2409 7.06834 13.0883 7.18998 12.9757C7.31163 12.8632 7.47662 12.8 7.64865 12.8V12.8Z" fill="currentColor"/>
    </SvgIcon>
  );
}