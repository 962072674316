
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
export default function point(props:any) {
    return (
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="1" fill="#888888"/>
            <circle cx="20" cy="30" r="1" fill="#888888"/>
            <circle cx="20" cy="10" r="1" fill="#888888"/>
            <circle cx="13" cy="25" r="1" fill="#888888"/>
            <circle cx="27" cy="15" r="1" fill="#888888"/>
            <circle cx="27" cy="25" r="1" fill="#888888"/>
            <circle cx="13" cy="15" r="1" fill="#888888"/>
            </svg>
    )
}

