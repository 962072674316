import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Duplicate(props:any) {
  return (
    <SvgIcon  {...props} viewBox='0 0 22 22'>
     <path d="M7.57895 13.0909H1.68421C1.23753 13.0909 0.809144 12.9185 0.493294 12.6116C0.177443 12.3048 0 11.8885 0 11.4545V1.63636C0 1.20237 0.177443 0.786158 0.493294 0.47928C0.809144 0.172402 1.23753 0 1.68421 0H11.7895V1.63636H1.68421V11.4545H7.57895V9.81818L10.9474 12.2727L7.57895 14.7273V13.0909ZM14.3158 16.3636V4.90909H5.05263V9.81818H3.36842V4.90909C3.36842 4.4751 3.54586 4.05888 3.86171 3.75201C4.17757 3.44513 4.60595 3.27273 5.05263 3.27273H14.3158C14.7625 3.27273 15.1909 3.44513 15.5067 3.75201C15.8226 4.05888 16 4.4751 16 4.90909V16.3636C16 16.7976 15.8226 17.2138 15.5067 17.5207C15.1909 17.8276 14.7625 18 14.3158 18H5.05263C4.60595 18 4.17757 17.8276 3.86171 17.5207C3.54586 17.2138 3.36842 16.7976 3.36842 16.3636V14.7273H5.05263V16.3636H14.3158Z" fill="currentColor"/>
    </SvgIcon>
  );
}

