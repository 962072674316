import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';
export default function Slides(props:any) {
    return (
        <SvgIcon  viewBox="0 0 22 22" fill="none" {...props}>
        <path d="M17.981 16.8537V1.2439C17.981 1.10927 17.8965 1 17.7923 1H1.18868C1.08453 1 1 1.10927 1 1.2439V16.8537C1 16.9884 1.08453 17.0976 1.18868 17.0976H17.7923C17.8965 17.0976 17.981 16.9884 17.981 16.8537ZM17.6036 16.6098H1.37736V1.48781H17.6036V16.6098Z" fill="currentColor" stroke="currentColor"/>
        <path d="M19.4906 18.8051V2.21961C19.4906 2.08498 19.4061 1.97571 19.3019 1.97571C19.1978 1.97571 19.1132 2.08498 19.1132 2.21961V18.5611H1.94356C1.83941 18.5611 1.75488 18.6704 1.75488 18.8051C1.75488 18.9397 1.83941 19.049 1.94356 19.049H19.3019C19.4061 19.049 19.4906 18.9397 19.4906 18.8051Z" fill="currentColor" stroke="currentColor"/>
        <path d="M20.8115 2.95117C20.7073 2.95117 20.6228 3.06044 20.6228 3.19508V20.5122H2.69844C2.59429 20.5122 2.50977 20.6215 2.50977 20.7561C2.50977 20.8908 2.59429 21 2.69844 21H20.8115C20.9156 21 21.0002 20.8908 21.0002 20.7561V3.19508C21.0002 3.06044 20.9156 2.95117 20.8115 2.95117Z" fill="currentColor" stroke="currentColor"/>
        </SvgIcon>
    )
}


