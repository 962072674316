import { TourData } from "../tour/Types";

const classPrefix = "#getting-started";

const DATA: TourData = {
  id: "getting_started",
  title: "Getting Started",
  description:
    "This tutorial walks you through the overall GUI " +
    " layout of VCollab  Enterprise. It is intended " +
    " to help new users to quickly accustomed to and " +
    " onboard VCollab Enterprise application.",
  steps: [
    {
      title: "Model Name",
      target: classPrefix + "-step1",
      description:
        "Title of the loaded model gets displayed in the " +
        "center of the top bar.",
    },
    {
      title: "3D Render Window",
      target: ".getting-started-step2",
      description:
        "This is the window in which VCollab 3D model is displayed to the user." +
        " User can interact with 3D model using mouse.",
      placement: 'bottom',
      actions: [
        <div>Move the mouse around holding the left to rotate the model.</div>,
        <div>Move the mouse around holding the middle to zoom the model.</div>,
        <div>Move the mouse around holding the right to open the model.</div>,
      ]
    },
    {
      title: "Toggle Fullscreen Mode",
      target: classPrefix + "-step3",
      description: "Enter  full screen mode.",
      actions: [
        <div>Click to switch fullscreen mode.</div>,
        <div>Click to exit fullscreen mode.</div>,
      ],
      placement: "bottom-start",
    },
    {
      target: classPrefix + "-step4",
      title: "Activity Bar",
      description:
        "Activity bar provides a quick way for the user to" +
        " access various GUI controls, menus and their groups.",
      // actions: [
      //   <div>Click to on <b>the top / bottom </b> arrows.</div>,
      //   <div>Scroll the <b>menu </b>iteams.</div>,
      // ],
    },
    {
      target: "#vertical-tab-DisplayMode",
      title: "Menu Selection",
      description:
        "Users can pin the frequently used menus on the" +
        " activity bar and access them quickly. Clicking on the" +
        " access bar items open the corresponding menu.",

      actions: [
        <div>
          Click on and activate "Display Mode" item on activity bar to open the
          display modes list in the sidebar.
        </div>,
      ],
    },
    {
      target: classPrefix + "-step6",
      title: "Side Bar",
      description:
        "Side bar is used to display components of the selected" +
        ' menu. Since user selected "Display Modes" menu, this' +
        " menu item gets activated in the activity bar and the" +
        " sidebar displays the list of display modes to the user.",
    },
    {
      target: "#displaymodes_Shaded",
      title: "Side Bar Item",
      description: "",
      actions: [<div>Select the "Shaded" in the display modes list.</div>],
    },
    {
      target: classPrefix + "-step8",
      title: "Action Button",
      description: 'Click on "Download and Show".',
      actions: [<div>Click on Download and Show.</div>],
      isFixed: true,
      disableBeacon: true,
    },
    {
      target: classPrefix + "-step9",
      title: "Notifications",
      description:
        "User can observe that certain actions result in user" +
        " notifications. New notifications appear over the 3D" +
        " render window and disappear after a few seconds.",
      // placement:'center',
      isFixed: true,
      placement: "bottom",
    },
    {
      target: "#vertical-tab-History",
      title: "History",
      description:
        "User can view the list of previous notifications" +
        'in the "History" Menu.',
      disableOverlay: true,
      actions: [
        <div>
          Click on "History" button on the activity bar to activate "History"
          menu.
        </div>,
      ],
    },
    {
      target: classPrefix + "-step6",
      title: "History Menu",
      description:
        "User can view the list of previous notifications" +
        ' in the "History" Menu.',
    },
    {
      title: "Undo",
      target: classPrefix + "-step12",
      description:
        "VCollab Enterprise allows user to revert certain" +
        " actions that were performed earlier using the Undo " +
        " button on the top bar. Redo button can be used to" +
        " perform the action that was Undone earlier.",
      disableScrolling: true,
      placement: "bottom",
      actions: [
        <div>
          Click on "Undo" to revert the display mode to “Bounding Box" .
        </div>,
      ],
    },
    {
      title: "Redo",
      target: classPrefix + "-step13",
      description:
        "VCollab Enterprise allows user to revert certain" +
        " actions that were performed earlier using the Undo " +
        " button on the top bar. Redo button can be used to" +
        " perform the action that was Undone earlier.",
      placement: "bottom",
      
      actions: [
        <div>Click on "Redo" to revert the display mode to "Shaded" .</div>,
      ],
    },
    {
      target: classPrefix + "-step14",
      title: "Menus",
      description:
        '"All Menus" action item allows the user to list' +
        " all the menus and UI groups provided by the" +
        " application. User can select any given menu" +
        " from the list and activate it",
      actions: [<div>Click on "Menus".</div>],
    },
    {
      target: classPrefix + "-step6",
      title: "Menus",
      description:
        "User can select any menu item or group from the" +
        " displayed list and display the corresponding menu" +
        ' in the side bar. "All Menus" also provide' +
        " functionality to search and filter the menu items as well",
    },
    {
      target: "#AssemblyTree",
      title: "Assembly Tree",
      description: 'Click on the "Assembly Tree"',
      disableScrolling: true,
      actions: [<div>Click on "AssemblyTree".</div>],
    },
    {
      target: classPrefix + "-step6",
      title: "Assembly Tree Menus",
      description:
        "User can select any menu item or group from the" +
        " displayed list and display the corresponding menu" +
        ' in the side bar. "All Menus" also provide' +
        " functionality to search and filter the menu items as well",
    },
    {
      target: "#vertical-tab-Guides",
      title: "Guides",
      description:
        "VCollab Enterprise provides multiple guides for" +
        " users to get familiar with various features and" +
        " functionality. Use Guides menu to see the list of" +
        " available guides and run the desired guides.",
      actions: [<div>Click on "Guides".</div>],
    },
    {
      target: classPrefix + "-step6",
      title: "Guides",
      description:
        "VCollab Enterprise provides multiple guides for" +
        " users to get familiar with various features and" +
        " functionality. Use Guides menu to see the list of" +
        " available guides and run the desired guides.",
    },
  ],
};

export default DATA;
