
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Label(props:any) {
  return (
    <SvgIcon  {...props}>
    <path d="M10.1903 1.01473L2.544 2.544L1.01473 10.1903C0.990241 10.3128 0.996361 10.4394 1.03254 10.5589C1.06873 10.6784 1.13385 10.7871 1.22214 10.8754L11.1246 20.7779C11.1951 20.8483 11.2787 20.9042 11.3707 20.9423C11.4627 20.9804 11.5613 21 11.6609 21C11.7605 21 11.8591 20.9804 11.9512 20.9423C12.0432 20.9042 12.1268 20.8483 12.1972 20.7779L20.7779 12.1972C20.8483 12.1268 20.9042 12.0432 20.9423 11.9512C20.9804 11.8591 21 11.7605 21 11.6609C21 11.5613 20.9804 11.4627 20.9423 11.3707C20.9042 11.2787 20.8483 11.1951 20.7779 11.1246L10.8754 1.22214C10.7871 1.13385 10.6784 1.06873 10.5589 1.03254C10.4394 0.996361 10.3128 0.990241 10.1903 1.01473V1.01473Z" fill="none" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 7C6.55228 7 7 6.55228 7 6C7 5.44772 6.55228 5 6 5C5.44772 5 5 5.44772 5 6C5 6.55228 5.44772 7 6 7Z" fill="none" stroke="currentColor" strokeWidth="0.8"/>
    </SvgIcon>
  );
}
