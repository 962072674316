import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';
function Animation(props:any) {
    return (
        <SvgIcon  viewBox="0 0 22 24" fill="none" {...props}>
        <path d="M20.6 21.7756H11.692C11.5536 21.5318 11.2958 21.3674 11 21.3674C10.7042 21.3674 10.4462 21.5318 10.308 21.7756H1.4C1.17911 21.7756 1 21.9584 1 22.1838C1 22.4092 1.17911 22.5919 1.4 22.5919H10.308C10.4464 22.8357 10.7042 23.0001 11 23.0001C11.2958 23.0001 11.5538 22.8357 11.692 22.5919H20.6C20.8209 22.5919 21 22.4092 21 22.1838C21 21.9584 20.8209 21.7756 20.6 21.7756Z" fill="currentColor" stroke="currentColor" strokeWidth="0.8"/>
        <path d="M12.6707 9.67978L9.89513 6.84758C9.82202 6.76686 9.71757 6.71606 9.60135 6.71606C9.38002 6.71606 9.20068 6.89906 9.20068 7.12491V12.8367H9.20091C9.20224 13.0614 9.38091 13.243 9.60135 13.243C9.71757 13.243 9.82202 13.1922 9.89513 13.1115L12.6707 10.2793C12.7498 10.2047 12.7996 10.0981 12.7996 9.97955C12.7996 9.86096 12.7496 9.75438 12.6707 9.67978ZM10.0005 11.8519V8.10722L11.8411 9.97955L10.0005 11.8519Z" fill="currentColor" stroke="currentColor" strokeWidth="0.8"/>
        <path d="M20.6 1H1.4C1.17911 1 1 1.18277 1 1.40816V18.551C1 18.7764 1.17911 18.9592 1.4 18.9592H20.6C20.8209 18.9592 21 18.7764 21 18.551V1.40816C21 1.18277 20.8209 1 20.6 1ZM1.8 18.1428V1.81633H20.2V18.1428H1.8Z" fill="currentColor" stroke="currentColor" strokeWidth="0.8"/>
        </SvgIcon>
    )
}

export default Animation
