import SvgIcon from '@material-ui/core/SvgIcon';

export default function Full(props:any) {
  return (
    <SvgIcon  viewBox="5 6 14 12" {...props}>
      <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" fill="currentColor"/>
    </SvgIcon>
  );
}

