import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PointLabel(props:any) {
	
	return (
	
		<SvgIcon {...props} viewBox="0 0 18 14">
		 
		 <path d="M12 6.49978C12 7.32847 11.3285 8 10.5002 8C9.67153 8 9 7.32851 9 6.49978C9 5.67152 9.67149 5 10.5002 5C11.3285 5 12 5.67149 12 6.49978Z" fill="currentColor"/>
         <path d="M12.3158 12H1.89474V2H12.3158L15.6789 7L12.3158 12ZM13.86 0.84C13.5189 0.33 12.9505 0 12.3158 0H1.89474C1.39222 0 0.910288 0.210714 0.554955 0.585786C0.199623 0.960859 0 1.46957 0 2V12C0 12.5304 0.199623 13.0391 0.554955 13.4142C0.910288 13.7893 1.39222 14 1.89474 14H12.3158C12.9505 14 13.5189 13.66 13.86 13.15L18 7L13.86 0.84Z" fill="currentColor"/>
		
		</SvgIcon>
	
	)
}